@import 'https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css';

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box-verifySheet {
	margin-top:10px;
    width: 100%;
	height:40px;
    position: relative;
	box-sizing: border-box;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	border-radius:25px;
	appearance:none;
	outline:none;
}


    .select-search-box-verifySheet::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #222f3e;
        z-index: 1;
    }

    
    .select-search-box-verifySheet__icon--disabled::after {
        content: none !important;
    }

    .select-search-box-verifySheet--input::after {
        display: none !important;
    }

    .select-search-box-verifySheet__out {
        display: none;
    }

    .select-search-box-verifySheet__search {
        display: block;
        width: 100%;
        height: 40px;
        border: none;
        background: none;
        outline: none;
        color: #222f3e;
        -webkit-appearance: none;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        cursor: pointer;
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        line-height: 40px;
        box-shadow: none;
    }

    input.select-search-box-verifySheet__search {
        line-height: 1;
    }

    .select-search-box-verifySheet__search {
        box-shadow: none;
    }

    .select-search-box-verifySheet--input .select-search-box-verifySheet__search {
        cursor: text;
    }

        .select-search-box-verifySheet__search:focus {
            cursor: text;
        }

        .select-search-box-verifySheet__search--placeholder {
            font-weight: 500;
        }

    .select-search-box-verifySheet input::-webkit-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input::-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-ms-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:30px;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box-verifySheet__select {
        display: none;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 4px;
        overflow: auto;
        box-shadow: none;
		border:1px solid #AAAAAA;
		box-sizing: border-box;
        z-index: 100;
        min-height: 49px;
    }

        .select-search-box-verifySheet__select--display {
            display: block;
        }

    .select-search-box-verifySheet__option {
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        color: #616b74;
        padding: 15px 20px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
    }

        .select-search-box-verifySheet__option:first-child {
            border-top: none;
        }

        .select-search-box-verifySheet__option--hover, .select-search-box-verifySheet__option:hover {
            background: #f4f7fa;
        }

        .select-search-box-verifySheet__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box-verifySheet__option--selected:hover, .select-search-box-verifySheet__option--selected.select-search-box-verifySheet__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box-verifySheet__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box-verifySheet__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }