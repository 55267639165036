@import url(https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css);
@import url(https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css);
@import url(https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css);
@import url(https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css);
@import url(https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css);
@import url(https://moresheet.s3-ap-southeast-1.amazonaws.com/cdn_css/fago.css);
.Style_navContainer__11duE {
  background-color: #ffffff;
  width: 100vw;
  max-width: 100%;
  height: 50px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-left: 15px;
  padding-right: 15px;
}

.Style_navBlock__1_q5P {
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 999;
  background-color: #ffffff;
}

.Style_logo__15lzb {
  height: 36px;
  margin-top: 7px;
}

.Style_logoText__9gRCw {
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 50px;
}

.Style_logoNav__3l69z {
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 50px;
}

.Style_cart__1uUY7 {
  height: 23px;
  margin-right: 16px;
  cursor: pointer;
}
.Style_cartSize__1NfkX {
  padding-left: 4px;
  padding-right: 4px;
  height: 14px;
  line-height: 14px;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 10px;
  background-color: #ff0000;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  color: white;
  position: absolute;
  top: 10px;
  left: 19px;
}

.Style_listLeft__DvE76 {
  cursor: pointer;
  height: 50px;
  margin-left: 24px;
  margin-right: 24px;
  color: #000000;
  text-align: center;
  line-height: 50px;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 16px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.Style_listLeft__DvE76.Style_active__1oVEX {
  font-weight: 800;
}

.Style_listLeft__DvE76:hover {
}

.Style_listLeft__DvE76.Style_active__1oVEX:hover {
}

.Style_menuCon__gCpax {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  margin-right: 50px;
}

.Style_rightCon__3-xl0 {
  height: 50px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  position: relative;
}

.Style_allright__ka0uH {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.Style_loginButton__16jxQ {
  cursor: pointer;
  height: 50px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  color: #333333;
  line-height: 50px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.Style_userPanel__3ADC2 {
  cursor: pointer;
  height: 50px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.Style_userPanel__3ADC2:hover {
  opacity: 0.7;
}
.Style_userPic__3iGWY {
  height: 36px;
  -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
          border-radius: 30px;
}
.Style_userText__1xuPH {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  width: 100px;
  margin-left: 8px;
  color: #333333;
}

@-webkit-keyframes Style_sidePa__QXwMF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes Style_sidePa__QXwMF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes Style_sidePa__QXwMF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Style_sidePa__QXwMF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Style_sidePanel__2JuVO {
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 50px;
  height: 165px;
  width: 180px;
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
     -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
          box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  -webkit-animation-name: Style_sidePa__QXwMF;
     -moz-animation-name: Style_sidePa__QXwMF;
       -o-animation-name: Style_sidePa__QXwMF;
          animation-name: Style_sidePa__QXwMF;
  -webkit-animation-duration: 0.5s;
     -moz-animation-duration: 0.5s;
       -o-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.Style_linkage__FbHCT {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: #333333;
  text-align: center;
  height: 40px;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.Style_linkage__FbHCT:hover {
  opacity: 0.7;
}

.Style_logout__a7ObB {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: red;
  text-align: center;
  height: 40px;
  cursor: pointer;
}

.Style_namePan__1mKSe {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  text-decoration: underline;
  border-bottom: 1px solid black;
  line-height: 30px;
  height: 30px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.Style_logoMid__2xbTk {
  height: 37px;
  position: absolute;
  left: -webkit-calc(50% - 36.45px);
  left: -moz-calc(50% - 36.45px);
  left: calc(50% - 36.45px);
  top: 6.5px;
}

.Style_cart__1uUY7 {
  height: 23px;
  cursor: pointer;
}

.Style_bell__k8TII {
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.Style_navContainerMobile__2t356 {
  background-color: #ffffff;
  width: 100vw;
  max-width: 100%;
  height: 50px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
}

.Style_navContainerMobile__2t356.Style_outer__1IamQ {
  display: none;
}

.Style_hamContainer__2myyn {
  width: 30px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.Style_line1__3EEv8,
.Style_line2__1fTlC,
.Style_line3__1N_F9 {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #000000;
  -webkit-border-radius: 9px;
     -moz-border-radius: 9px;
          border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.Style_line1__3EEv8 {
  top: 0px;
}
.Style_line1__3EEv8.Style_open__3GVTu {
  top: 9px;
  -webkit-transform: rotate(135deg);
     -moz-transform: rotate(135deg);
       -o-transform: rotate(135deg);
          transform: rotate(135deg);
}
.Style_line2__1fTlC {
  top: 9px;
}
.Style_line2__1fTlC.Style_open__3GVTu {
  opacity: 0;
  left: -60px;
}
.Style_line3__1N_F9 {
  top: 18px;
}
.Style_line3__1N_F9.Style_open__3GVTu {
  top: 9px;
  -webkit-transform: rotate(-135deg);
     -moz-transform: rotate(-135deg);
       -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

.Style_navPanelMobile__35NuV {
  background-color: #ffffff;
  width: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-box-shadow: 0 3px 2px -2px black;
     -moz-box-shadow: 0 3px 2px -2px black;
          box-shadow: 0 3px 2px -2px black;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 50px;
  height: 100vh;
}

.Style_navPanelContent__2FjmW {
  width: 100%;
  height: -webkit-calc(100vh - 150px);
  height: -moz-calc(100vh - 150px);
  height: calc(100vh - 150px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.Style_navPanelMobile__35NuV.Style_open__3GVTu {
  opacity: 1;
  z-index: 299;
}

.Style_cartMobile__13iUq {
  height: 23px;
  cursor: pointer;
}
.Style_cartSizeMobile__fBkyA {
  padding-left: 4px;
  padding-right: 4px;
  height: 14px;
  line-height: 14px;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 10px;
  background-color: #ff0000;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  color: white;
  position: absolute;
  top: 3px;
  left: 19px;
}

.Style_listMobile__1T3ih {
  cursor: pointer;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #333333;
  text-align: center;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  opacity: 0.3;
}

.Style_listMobile__1T3ih.Style_active__1oVEX {
  opacity: 1;
}

.Style_listMobile__1T3ih:hover {
  opacity: 0.7;
}

.Style_listMobile__1T3ih.Style_active__1oVEX:hover {
  opacity: 1;
}

.Style_mobileBreak__NHE95 {
  border: 0.5px solid #333333;
  opacity: 0.3;
}

.Style_loginButtonMobile__2OHbW {
  cursor: pointer;
  height: 50px;
  width: 120px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  color: #333333;
  line-height: 50px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Style_userPicMobile__3mlaG {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  height: 100px;
  width: 100px;
}
.Style_userTextMobile__3NwVi {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.Style_rightMobile__lNjEu {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  position: relative;
}

.Style_userPicNavMobile__4if5Y {
  height: 36px;
  -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
          border-radius: 30px;
  margin-left: 16px;
}

.Style_logoutMobile__Lu9KW {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  height: 30px;
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  color: red;
  text-align: center;
  line-height: 30px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.Style_unread__FgbCl {
  font-family: "Mitr", sans-serif;
  font-weight: 700;
  font-size: 16px;
  width: 300px;
  height: 40px;
  border: 1px solid #ff0000;
  position: fixed;
  top: 50px;
  left: -webkit-calc(50vw - 150px);
  left: -moz-calc(50vw - 150px);
  left: calc(50vw - 150px);
  z-index: 10000;
  text-align: center;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: #000000;
  background-color: #f1b3b3;
  line-height: 40px;
}

.Style_unread__FgbCl:hover {
  color: #ffffff;
  background-color: #ff0000;
}

.Style_popupimgcon__3uOkW {
  width: -webkit-calc(100vw - 20px);
  width: -moz-calc(100vw - 20px);
  width: calc(100vw - 20px);
  height: -webkit-calc(100vw - 20px);
  height: -moz-calc(100vw - 20px);
  height: calc(100vw - 20px);
  max-width: 600px;
  max-height: 600px;
  position: relative;
}

.Style_popupimg__37LSz {
  width: 100%;
  height: 100%l;
}

.Style_popup__3ydNR {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 100%;
}

.Style_popupText__23zIx {
  width: 64%;
  height: 6.4%;
  top: 29%;
  left: 18%;
  outline: none;
  position: absolute;
  z-index: 1000001;
  border: 2px solid black;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 5px;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Style_popupClose__2EF8b {
  top: 1%;
  right: 2%;
  outline: none;
  position: absolute;
  z-index: 1000001;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: #ff0000;
  cursor: pointer;
}

.Style_popupCloseNewYear__2LD20 {
  top: 1%;
  right: 2%;
  outline: none;
  position: absolute;
  z-index: 1000001;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 25px;
  background-color: #000000;
  color: #ffffff;
  -webkit-border-radius: 25px;
     -moz-border-radius: 25px;
          border-radius: 25px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.Style_popupButt__oCyMl {
  top: 36.5%;
  left: 42%;
  width: 20%;
  height: 5%;
  outline: none;
  position: absolute;
  z-index: 1000001;
  background-color: transparent;
  cursor: pointer;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.Style_searchBox__3XTkC {
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  height: 45px;
  width: 327px;
  border-radius: 10px;
  margin-top: 20px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.Style_mag__10A4i {
  width: 30px;
  height: 30px;
  position: relative;
  margin-left: 20px;
}

.Style_mag1__rIgI8 {
  position: absolute;
}

.Style_mag2__3Ql5j {
  position: absolute;
  top: 20px;
  left: 20px;
}

.Style_textBox__3yILl {
  width: 250px;
  height: 25px;
  margin-right: 10px;
  border: none;

  font-family: "Mitr";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.Style_searchSheets__3I7n5 {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin-top: 30px;
  overflow-x: scroll;
}

.Style_wrapper__KxMwh {
	width:180px;
	min-height:200px;
	background:transparent;
	margin-left:10px;
	margin-right:10px;
	margin-bottom:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_rec__3PIH6 {
	width:50px;
	height:50px;
	position: absolute;
	right:-10px;
	top:-10px;
	-webkit-border-radius:100px;
	   -moz-border-radius:100px;
	        border-radius:100px;
}

.Style_recmobile__3de1_ {
	width:50px;
	height:50px;
	position: absolute;
	left:110px;
	top:-10px;
	-webkit-border-radius:100px;
	   -moz-border-radius:100px;
	        border-radius:100px;
}

.Style_cover__2JGxT {
	width:178px;
	height:178px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	cursor:pointer;
}

.Style_starRevBig__2qzDZ {
	height:20px;
	position:relative;
	top:4px;
}
.Style_star__18yn5 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:8px;
	color:black;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
	position:relative;
}

.Style_sold__3PL4F {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:10px;
	color:#000000;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_addCart__2JCal {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_wrapperMobile__2muUP {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	width:100%;
	height:170px;
	margin-top:20px;
	border-bottom:1px solid #AAAAAA;
	position: relative;
}
.Style_coverMobile__qTd2M {
	width:148px;
	height:148px;
	cursor:pointer;
}
.Style_starMobile__3nOZd {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:black;
	position:relative;
}

.Style_soldMobile__3SZ1H {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:#000000;
	margin-top:10px;
}

.Style_addCartMobile__2sCwY {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_infoMobile__1ekGA {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_picWrapperMobile__2lDY9 {
	width:148px;
	height:148px;
	position:relative;
}
.Style_body__24pag {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  text-align: center;
  color: #ffc700;
}

.Style_slide2__3aeMo {
  width: 100vw;
  background-color: #fdfdfd;
  padding-top: 75px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 50px;
}
.Style_slide3__1dAVv {
  width: 100vw;
  background-color: #fdfdfd;
  padding-top: 50px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 50px;
}
.Style_slide4__17_mz {
  width: 100vw;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #fdfdfd), to(#fffbee));
  background: -webkit-linear-gradient(top, #fdfdfd 36.02%, #fffbee 100%);
  background: -moz-linear-gradient(top, #fdfdfd 36.02%, #fffbee 100%);
  background: -o-linear-gradient(top, #fdfdfd 36.02%, #fffbee 100%);
  background: linear-gradient(180deg, #fdfdfd 36.02%, #fffbee 100%);
  padding-top: 72px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 100px;
  border-bottom: 2px solid #e2e2e2;
}

.Style_waveContainer__spib7 {
  width: 100vw;
  max-height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

.Style_wave__2qNBv {
  width: 100%;
}

.Style_man__E_Hfd {
  position: absolute;
  top: 14vw;
  right: 19vw;
  width: 17vw;
}

.Style_board__lspe3 {
  position: absolute;
  top: 10vw;
  right: 4vw;
  width: 21vw;
}

.Style_topSlide__5Ol_4 {
  background-color: #fdfdfd;
  width: 100vw;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.Style_topSpace__jdbgJ {
  width: 100vw;
  max-width: 100%;
  height: 6vw;
}

.Style_bigHead__J-tNu {
  font-family: "Mitr", sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 75px;
  text-align: left;
  color: #000000;
}

.Style_sectionM__1a_fz {
  font-family: "Mitr", sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 57px;
  text-align: left;
  color: #000000;
}

.Style_smallHead__s6PnQ {
  font-family: "Mitr", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #666666;
  margin-top: 29px;
  line-height: 38px;
}

.Style_buttonRow__3lejC {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  margin-top: 48px;
}

.Style_buyButt__1mKSw {
  font-family: "Mitr", sans-serif;
  font-size: 24px;
  font-weight: 500;
  width: 300px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: -webkit-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -moz-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -o-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: linear-gradient(100.23deg, #ffe992 0%, #ffcd09 100%);
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
}

.Style_buyTicketButt__3-WGO {
  font-family: "Mitr", sans-serif;
  font-size: 24px;
  font-weight: 500;
  width: 300px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
}

.Style_sellButt__18kod {
  margin-left: 31px;
  font-family: "Mitr", sans-serif;
  font-size: 24px;
  font-weight: 500;
  width: 300px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
}

.Style_lsText__2ucQN {
  margin-left: 31px;
  font-family: "Mitr", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 60px;
  -webkit-text-decoration-line: underline;
     -moz-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #000000;
}

.Style_uniContainer__3dEH_ {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_uniPic__3_7iy {
  height: 200px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  cursor: pointer;
}

.Style_newUni__OfOTR {
  margin-top: 45px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_newUniLabel__1oENI {
  font-family: Mitr;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}

.Style_newUniLink__1ZBeL {
  font-family: Mitr;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
  background: -webkit-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  background: -moz-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  background: -o-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  background: linear-gradient(
    117.97deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  width: 300px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  margin-top: 20px;
}

.Style_allsaContainer__2Rpmz {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.Style_thai__aCMnT {
  margin-right: 50px;
}

.Style_statContainer__XWVPJ {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_statBox__1B3-- {
  width: 350px;
}

.Style_statDescription__3-VbB {
  font-family: Mitr;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  color: #666666;
  text-align: left;
}

.Style_statValue__3TRWr {
  font-family: Mitr;
  font-weight: 600;
  font-size: 50px;
  color: #333333;
  text-align: left;
}

.Style_expHeader__2e6o8 {
  font-family: Mitr;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
}

.Style_leftBox__2Svir {
  width: 373px;
  height: 157px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;
  font-family: Mitr;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #666666;
}

.Style_rightBox__2KP3I {
  width: 373px;
  height: 157px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;
  font-family: Mitr;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #666666;
}

.Style_speechLeft__s5GsA {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
          align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
}

.Style_speechRight__2S11- {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
          align-items: flex-end;
  position: absolute;
  right: 0;
  top: 170px;
}

.Style_reviewContainer__2Cn2d {
  margin-top: 60px;
  position: relative;
  height: 300px;
}

.Style_leftAvatar__1VMd6 {
  width: 70px;
  height: 70px;
}

.Style_rightAvatar__t8NjA {
  width: 70px;
  height: 70px;
}

.Style_readyHeader__3B6A4 {
  margin-top: 100px;
  font-family: Mitr;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
}

.Style_buttonRowCenter__Ta_EU {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  margin-top: 53px;
}

.Style_smallReady__263kq {
  font-family: "Mitr", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  margin-top: 24px;
  line-height: 36px;
}

/*start mobile*/
.Style_slide2Small__29kSH {
  width: 100vw;
  background-color: #fdfdfd;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 50px;
  border-bottom: 2px solid #e2e2e2;
}
.Style_slide3Small__3_a0o {
  width: 100vw;
  background-color: #fdfdfd;
  padding-top: 50px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 50px;
  border-bottom: 2px solid #e2e2e2;
}
.Style_slide4Small__2c87p {
  width: 100vw;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #fdfdfd), to(#fffbee));
  background: -webkit-linear-gradient(top, #fdfdfd 36.02%, #fffbee 100%);
  background: -moz-linear-gradient(top, #fdfdfd 36.02%, #fffbee 100%);
  background: -o-linear-gradient(top, #fdfdfd 36.02%, #fffbee 100%);
  background: linear-gradient(180deg, #fdfdfd 36.02%, #fffbee 100%);
  padding-top: 72px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 100px;
  border-bottom: 2px solid #e2e2e2;
}

.Style_waveContainerSmall__1XgCE {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Style_waveSmall__UxddO {
  width: 100%;
}

.Style_topSlideSmall__3vxbd {
  background-color: #fdfdfd;
  width: 100vw;
  max-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 2px solid #e2e2e2;
  position: relative;
  min-height: 490px;
  padding-bottom: 40px;
}

.Style_topSpaceSmall__8bivx {
  width: 100vw;
  max-width: 100%;
  height: 100px;
}

.Style_bigHeadSmall__15SMj {
  font-family: "Mitr", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: #000000;
}

.Style_smallHeadSmall__1Db0G {
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #666666;
  margin-top: 29px;
  line-height: 20px;
}

.Style_buyButtSmall__1rOxw {
  margin-top: 40px;
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 255px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: -webkit-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -moz-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -o-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: linear-gradient(100.23deg, #ffe992 0%, #ffcd09 100%);
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
}

.Style_sellButtSmall__ckvYM {
  margin-top: 20px;
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 255px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
}

.Style_uniContainerSmall__1xIfR {
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.Style_uniPicSmall__3u-7M {
  height: 100px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  display: block;
  cursor: pointer;
}

.Style_newUniSmall__2z61s {
  margin-top: 45px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_newUniLabelSmall__3gOcU {
  font-family: Mitr;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}

.Style_newUniLinkSmall__1ASfh {
  margin-left: 7px;
  font-family: Mitr;
  font-weight: 400;
  font-size: 16px;
  color: #eecc1f;
  cursor: pointer;
}

.Style_statContainerSmall__3gLuC {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_statBoxSmall__3oDw_ {
  width: 170px;
}

.Style_statDescriptionSmall__2cgw5 {
  font-family: Mitr;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #666666;
  text-align: left;
}

.Style_statValueSmall__3GJaR {
  font-family: Mitr;
  font-weight: 600;
  font-size: 40px;
  color: #333333;
  text-align: left;
}

.Style_expHeaderSmall__3YgI5 {
  font-family: Mitr;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
}

.Style_uniListHeaderSmall__L_Bwk {
  font-family: Mitr;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
  margin-top: 72px;
}

.Style_leftBoxSmall__3Wv0K {
  width: 330px;
  height: 138px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 18px;
  font-family: Mitr;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: #666666;
  position: relative;
}

.Style_rightBoxSmall__-YkHu {
  width: 330px;
  height: 138px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 18px;
  font-family: Mitr;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: #666666;
  position: relative;
}

.Style_speechLeftSmall__2sqKj {
  position: absolute;
  top: 0;
  left: 0;
}

.Style_speechRightSmall__16dTa {
  position: absolute;
  right: 0;
  top: 170px;
}

.Style_reviewContainerSmall__346M0 {
  margin-top: 40px;
  position: relative;
  height: 300px;
}

.Style_readyHeaderSmall__1N0Ja {
  margin-top: 50px;
  font-family: Mitr;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
}

.Style_smallReadySmall__2gzX3 {
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  margin-top: 24px;
  line-height: 20px;
}

.Style_buyButtSmallCenter__3ULh7 {
  margin-top: 40px;
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 255px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: -webkit-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -moz-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -o-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: linear-gradient(100.23deg, #ffe992 0%, #ffcd09 100%);
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.Style_sellButtSmallCenter__38Oj0 {
  margin-top: 20px;
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 255px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.Style_banner375__3ioTT {
  width: 100%;
  margin-bottom: 20px;
}

.Style_banner720__2E1iQ {
  width: 100%;
  margin-bottom: 20px;
}

.Style_banner930__2wxvS {
  width: 100%;
  margin-bottom: 20px;
}

.Style_newUnicon__1vfdo {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.Style_unicat__G-v_G {
  width: 200px;
  height: 200px;
  /* moresheet ctr */
  background: -webkit-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  background: -moz-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  background: -o-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  background: linear-gradient(
    117.97deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  /* shadow2 */
  -webkit-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
     -moz-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
          box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #000000;
  font-family: Mitr;
  font-size: 30px;
  font-weight: 500;
  line-height: 200px;
  text-align: center;
}

.Style_unicatSmall__c_TlZ {
  width: 90px;
  height: 90px;
  /* moresheet ctr */
  background: -webkit-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  background: -moz-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  background: -o-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  background: linear-gradient(
    117.97deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f4d460 42.4%
  );
  /* shadow2 */
  -webkit-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
     -moz-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
          box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #000000;
  font-family: Mitr;
  font-size: 14px;
  font-weight: 500;
  line-height: 90px;
  text-align: center;
}

.Style_uniPicConNew__1luCW {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_uniPicConNewSmall__33utw {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 20px;
}

.Style_unicatback__2K4f9 {
  font-family: Mitr;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  background: -webkit-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  background: -moz-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  background: -o-linear-gradient(
    332.03deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  background: linear-gradient(
    117.97deg,
    rgba(247, 226, 158, 0.505209) 9.59%,
    #f7d04d 42.4%
  );
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  width: 200px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.Style_unicatmat__jPjix {
  width: 200px;
  height: 200px;
  -webkit-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
     -moz-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
          box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: transparent;
}

.Style_unicatmatSmall__1LUo8 {
  width: 90px;
  height: 90px;
  -webkit-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
     -moz-box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
          box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.1);
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: transparent;
}

.Style_unicat__G-v_G:hover {
  opacity: 0.8;
}

/* .unicatSmall:hover {
  opacity: 0.8;
} */

.Style_goldButton__1bEHr {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	   -moz-box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	        box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	height:90px;
	width:90px;
	-webkit-border-radius:90px;
	   -moz-border-radius:90px;
	        border-radius:90px;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	margin-top:20px;
	text-align:center;
	-webkit-transition:0.3s;
	-o-transition:0.3s;
	-moz-transition:0.3s;
	transition:0.3s;
}
.Style_goldButton__1bEHr:hover {
	opacity:0.7;
}
.Style_fLogo__2RD5F {
	width:20px;
	margin-top:26px;
	-webkit-transition:0.3s;
	-o-transition:0.3s;
	-moz-transition:0.3s;
	transition:0.3s;
}
.Style_footer__wVatZ {
	background-color:#333333;
	width:100%;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding-top:48px;
	padding-bottom:48px;
}

.Style_allContainer__mQzPY {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	        justify-content:space-between;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
}

.Style_logo__dGxcr {
	font-family: Heebo;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	color:#ffffff;
}

.Style_copyRight__ZgMNf {
	margin-top:6px;
	opacity:0.7;
	font-family: Mitr;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color:#FFFFFF;
}

.Style_rightContainer__3_izj {
	
}

.Style_menuContainer__15Xbi {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	        justify-content:flex-end;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	margin-top:5px;
}

.Style_footerMenu__1adQs {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color:#FFFFFF;
	margin-bottom:24px;
	margin-left:48px;
	text-align:right;
	white-space: nowrap;
}

.Style_detailFooter__uZe-e {
	font-family: Mitr;
	font-weight: 400;
	font-size: 12px;
	line-height: 22px;
	text-align: right;
	color:#FFFFFF;
	opacity:0.7;
}

.Style_rightContainerSmall__1lS4S {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	        justify-content:flex-end;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
}

.Style_menuContainerSmall__1IfBZ {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	        justify-content:space-between;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	margin-top:5px;
	width:180px;
}

.Style_footerMenuSmall__1E9Av {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color:#FFFFFF;
	margin-bottom:14px;
	width:70px;
	text-align:right;
	white-space: nowrap;
}

.Style_detailFooterSmall__3WO22 {
	font-family: Mitr;
	font-weight: 400;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
	color:#FFFFFF;
	opacity:0.7;
	margin-top:20px;
}
.Style_shopPage__H3c42 {
	width:100vw;
	max-width:100%;
	min-height:-webkit-calc(100vh - 189px);
	min-height:-moz-calc(100vh - 189px);
	min-height:calc(100vh - 189px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_topBlank__2BnGX {
	height:120px;
}

.Style_loginBox__Xqbat {
	width:350px;
	height:420px;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__1TZzU {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:center;
	margin-top:24px;
}

.Style_logo__bhPQ2 {
	height:50px;
	margin-left:auto;
	margin-right:auto;
	display:block;
}

.Style_textForm__2zTIp {
	display:block;
	margin-left:auto;
	margin-right:auto;
	height:30px;
	width:300px;
	margin-top:10px;
	border:2px solid black;
	padding-left:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
}

.Style_emailButton0__6gKDD {
	display:block;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	height:40px;
	background-color:#ffc700;
	margin-top:10px;
	color:black;
	border:2px solid black;
	line-height:40px;
	text-align:center;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	-webkit-transition:background-color 0.3s;
	-o-transition:background-color 0.3s;
	-moz-transition:background-color 0.3s;
	transition:background-color 0.3s;
}

.Style_emailButton1__3UmE6 {
	display:block;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	height:40px;
	background-color:#ffc700;
	margin-top:10px;
	color:black;
	border:2px solid black;
	line-height:40px;
	text-align:center;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:16px;
	-webkit-transition:background-color 0.3s;
	-o-transition:background-color 0.3s;
	-moz-transition:background-color 0.3s;
	transition:background-color 0.3s;
}

.Style_emailButton0__6gKDD:hover, .Style_emailButton1__3UmE6:hover {
	background-color:#ffe896;
}

.Style_tos1__3WIFp {
	font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:10px;
	text-align:center;
	display:block;
	color:#aaaaaa;
	min-width:320px;
	margin-top:30px;
}

.Style_tos0__1dmBO {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:10px;
	text-align:center;
	display:block;
	color:#aaaaaa;
	min-width:320px;
	margin-top:15px;
}

.Style_forgot1__3Xbv_ {
	font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:12px;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	margin-top:5px;
	text-align:right;
	color:#3399ff;
	text-decoration:underline;
}
.Style_forgot0__33Xrb {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	margin-top:5px;
	text-align:right;
	color:#3399ff;
	text-decoration:underline;
}

.Style_linkk__2NCqk {
	color:#3399ff;
	cursor:pointer;
	display:inline;
}

.Style_loader__35YDR {
	max-width:100%;
	width:100vw;
	height:100vh;
	background-color:white;
	opacity:0.1;
	position:fixed;
	top:0;
	left:0;
}

.Style_terms__JYwxk {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	text-align:center;
	color:#3399ff;
	margin-top:20px;
	width:250px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.Style_goldButton__3h0Cq {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	   -moz-box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	        box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	height:90px;
	width:90px;
	-webkit-border-radius:90px;
	   -moz-border-radius:90px;
	        border-radius:90px;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	margin-top:20px;
	text-align:center;
	-webkit-transition:0.3s;
	-o-transition:0.3s;
	-moz-transition:0.3s;
	transition:0.3s;
}
.Style_goldButton__3h0Cq:hover {
	opacity:0.7;
}
.Style_gLogo__3k66D {
	width:40px;
	margin-top:26px;
	-webkit-transition:0.3s;
	-o-transition:0.3s;
	-moz-transition:0.3s;
	transition:0.3s;
}

.Style_banner350__3_Rtq {
	width:100%;
}

.Style_hold__2FgN4 {
	width:350px;
	margin-left:auto;
	margin-right:auto;
	margin-top:20px;
}
.Style_pageWrap__1OQhF {
	width:100vw;
	max-width:100%;
	min-height:-webkit-calc(100vh - 189px);
	min-height:-moz-calc(100vh - 189px);
	min-height:calc(100vh - 189px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_topBlank__G1uPc {
	height:160px;
}

.Style_bottomBlank__EHuA6 {
	height:145px;
}

.Style_loginBox__2wk0J {
	width:350px;
	max-width:100%;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	padding-left:16px;
	padding-right:16px;
	padding-bottom:30px;
}

.Style_header__JlVg8 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:center;
	margin-top:24px;
}

.Style_label__3cT63 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:16px;
	margin-bottom:15px;
}

.Style_inputText__34vi- {
	display:block;
	width:100%;
	height:37px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:37px;
	padding-left:10px;
	padding-right:10px;
	color:#000000;
}

.Style_logo__3778c {
	height:50px;
	margin-left:auto;
	margin-right:auto;
	display:block;
}

.Style_loader__14sJ_ {
	max-width:100%;
	width:100vw;
	height:100vh;
	background-color:white;
	opacity:0.1;
	position:fixed;
	top:0;
	left:0;
}

.Style_yesbutt__1TOkr {
	width:40px;
	height:40px;
	background-color:#4BB543;
	line-height:40px;
	text-align:center;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	cursor:pointer;
	border:2px solid black;
	-webkit-border-radius:30px;
	   -moz-border-radius:30px;
	        border-radius:30px;
}

.Style_nobutt__3XB4C {
	width:40px;
	height:40px;
	background-color:red;
	line-height:40px;
	text-align:center;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	cursor:pointer;
	border:2px solid black;
	-webkit-border-radius:30px;
	   -moz-border-radius:30px;
	        border-radius:30px;
}

.Style_goButton__NIhFi {
	display:block;
	width:100%;
	height:40px;
	margin-top:20px;
	color:black;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(102.91deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_goButton__NIhFi:hover {
	opacity:0.7;
}
[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box {
    width: 100%;
	height:37px;
    position: relative;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

    .select-search-box--multiple {
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        -webkit-box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
           -moz-box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
                box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        overflow: hidden;
    }

    .select-search-box::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 37px;
        color: #222f3e;
        z-index: 1;
    }

    .select-search-box--multiple::after, .select-search-box--focus::after {
        content:"\f002";
    }
    
    .select-search-box__icon--disabled::after {
        content: none !important;
    }

    .select-search-box--input::after {
        display: none !important;
    }

    .select-search-box__out {
        display: none;
    }

    .select-search-box__search {
        display: block;
        width: 100%;
        height: 37px;
        border: none;
        background: none;
        outline: none;
        padding-left: 10px;
		padding-right:40px;
        color: #222f3e;
        -webkit-appearance: none;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        position: relative;
        z-index: 2;
        cursor: pointer;
        font-family: 'Mitr', sans-serif;
		font-weight:400;
		font-size:16px;
        line-height: 37px;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    input.select-search-box__search {
        line-height: 1;
    }

    .select-search-box--multiple .select-search-box__search {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    .select-search-box--input .select-search-box__search {
        cursor: text;
    }

        .select-search-box__search:focus {
            cursor: text;
        }

        .select-search-box__search--placeholder {
            font-weight: 400;
        }

    .select-search-box input::-webkit-input-placeholder {
        color: #ccc;
        font-weight: 400;
		line-height:37px;
    }

    .select-search-box input::-moz-placeholder {
        color: #ccc;
        font-weight: 400;
		line-height:37px;
    }

    .select-search-box input:-moz-placeholder {
        color: #ccc;
        font-weight: 400;
		line-height:37px;
    }

    .select-search-box input:-ms-input-placeholder {
        color: #ccc;
        font-weight: 400;
		line-height:37px;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box__select {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        background: #fff;
        overflow: auto;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
		background: #FAFAFA;
		border: 1px solid #EAEAEA;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
		-webkit-border-radius: 5px;
		   -moz-border-radius: 5px;
		        border-radius: 5px;
        z-index: 100;
        min-height: 49px;
    }

    .select-search-box--multiple .select-search-box__select {
        display: block;
        position: static;
        border-top: 1px solid #eee;
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

        .select-search-box__select--display {
            display: block;
        }

	.select-search-box__options {
        padding-left:20px;
		padding-right:20px;
    }

    .select-search-box__option {
        font-family: 'Mitr', sans-serif;
		font-weight:400;
		font-size:16px;
        color: #616b74;
        padding: 0px 0px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
		height:37px;
		line-height:37px;
    }

        .select-search-box__option:first-child {
            border-top: none;
        }

        .select-search-box__option--hover, .select-search-box__option:hover {
            background: #f4f7fa;
        }

        .select-search-box__option--selected {
            background: #FAF1B5;
        }

            .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
                background: #FCE279;
            }

        .select-search-box__group {
            margin-top: 20px;
            padding-top: 20px;
            position: relative;
        }
		
		.select-search-box__row {
            border:none;
        }

        .select-search-box__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
               -moz-transform: translate(-50%, -50%);
                 -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }
.Style_wrapper__ZC55Q {
	width:180px;
	min-height:200px;
	background:transparent;
	margin-left:10px;
	margin-right:10px;
	margin-bottom:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_rec__l7clO {
	width:50px;
	height:50px;
	position: absolute;
	right:-10px;
	top:-10px;
	-webkit-border-radius:100px;
	   -moz-border-radius:100px;
	        border-radius:100px;
}

.Style_recmobile__3ne3Z {
	width:50px;
	height:50px;
	position: absolute;
	left:110px;
	top:-10px;
	-webkit-border-radius:100px;
	   -moz-border-radius:100px;
	        border-radius:100px;
}

.Style_cover__3qSsj {
	width:178px;
	height:178px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	cursor:pointer;
}

.Style_starRevBig__1MfM3 {
	height:20px;
	position:relative;
	top:4px;
}
.Style_star__Y7Jmg {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:8px;
	color:black;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
	position:relative;
}

.Style_sold__2v3iR {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:10px;
	color:#000000;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_addCart__3YvHv {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_wrapperMobile__2fGgP {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	width:100%;
	height:170px;
	margin-top:20px;
	border-bottom:1px solid #AAAAAA;
	position: relative;
}
.Style_coverMobile__1TTQC {
	width:148px;
	height:148px;
	cursor:pointer;
}
.Style_starMobile__1MFCb {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:black;
	position:relative;
}

.Style_soldMobile__2OFBf {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:#000000;
	margin-top:10px;
}

.Style_addCartMobile__9XBbH {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_infoMobile__3xJRo {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_picWrapperMobile__3r9oY {
	width:148px;
	height:148px;
	position:relative;
}
.Style_wrapper__30_yr {
	width:180px;
	min-height:200px;
	background:transparent;
	margin-left:10px;
	margin-right:10px;
	margin-bottom:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_cover__oXqjB {
	width:178px;
	height:178px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	cursor:pointer;
}

.Style_starRevBig__7t4NU {
	height:20px;
	position:relative;
	top:4px;
}
.Style_star__3jvRn {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:8px;
	color:black;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
	position:relative;
}

.Style_sold__3RC4A {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:10px;
	color:#000000;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_addCart__cLuuu {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_wrapperMobile__1reGb {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	width:100%;
	height:170px;
	margin-top:20px;
	border-bottom:1px solid #AAAAAA;
}
.Style_coverMobile__2QXsh {
	width:148px;
	height:148px;
	cursor:pointer;
}
.Style_starMobile__329J2 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:black;
	position:relative;
}

.Style_soldMobile__1_2eU {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:#000000;
	margin-top:10px;
}

.Style_addCartMobile__1clVU {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_infoMobile__3mRHj {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}
.Style_shopPage__RecYr {
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.Style_bigHeader__2NMxS {
  font-family: "Mitr", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
}

.Style_blanker__34TT7 {
  height: 80px;
}

.Style_sellLinkLine__2QSVh {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
}

.Style_sellLinkText__3IqSc {
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
}

.Style_sellLinkLink__2pwO7 {
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
  color: #eecc1f;
}

.Style_filterBar__2P-jG {
  margin-top: 40px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
     -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
          box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  width: 935px;
  margin-left: auto;
  margin-right: auto;
}

.Style_barMobile__3_R9d {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
     -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
          box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2),
    0px 2px 4px rgba(211, 211, 211, 0.05);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.Style_barMobile__3_R9d.Style_searcher__3DRYL {
  width: 290px;
  margin-top: 40px;
}

.Style_barMobile__3_R9d.Style_store__9HvDv {
  width: 290px;
  margin-top: 20px;
}

.Style_barMobile__3_R9d.Style_filter__1nIDq {
  width: 290px;
  margin-top: 20px;
}

.Style_nothing__3GmnX {
  color: #bdbdbd;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.Style_sheetWrapper__tGuq_ {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_semSelector__yrSO4 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 1px solid #eaeaea;
  height: 34px;
  color: #666666;
  cursor: pointer;
  background-color: #ffffff;
}

.Style_semSelector__yrSO4:hover {
  background-color: #f5f5f5;
}

.Style_semSelector__yrSO4.Style_mobile__3pLfN {
  min-width: 142px;
  border: none;
  background-color: #ffffff;
}

.Style_semSelector__yrSO4.Style_mobile__3pLfN.Style_last__3OJX5 {
  min-width: 142px;
  border-left: 1px solid #eaeaea;
}

.Style_search__hAr6J {
  display: block;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  height: 34px;
  width: 280px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  color: #666666;
  border-right: 1px solid #eaeaea;
  background-color: #ffffff;
}

.Style_search__hAr6J.Style_mobile__3pLfN {
  border-right: none;
}

.Style_rowWrapper__1GDgQ {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 20px;
}

.Style_loader__11SIS {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #eecc1f;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: Style_spin__1ynbQ 2s linear infinite;
     -moz-animation: Style_spin__1ynbQ 2s linear infinite;
       -o-animation: Style_spin__1ynbQ 2s linear infinite;
          animation: Style_spin__1ynbQ 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes Style_spin__1ynbQ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-moz-keyframes Style_spin__1ynbQ {
  0% {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
         transform: rotate(360deg);
  }
}

@-o-keyframes Style_spin__1ynbQ {
  0% {
    -o-transform: rotate(0deg);
       transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
       transform: rotate(360deg);
  }
}

@keyframes Style_spin__1ynbQ {
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Style_fillbot__3DhyK {
  min-height: -webkit-calc(100vh - 536px);
  min-height: -moz-calc(100vh - 536px);
  min-height: calc(100vh - 536px);
}

.Style_spacebot__2ULZi {
  height: 35px;
}
.Style_uniPic__3vZMs {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  border: 1px solid black;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.Style_uniPic2__3Mhj1 {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  border: 1px solid black;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.Style_uniPicBig__3Gecd {
  width: 120px;
  height: 120px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  border: 3px solid black;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.Style_uniPicBig2__kzSBL {
  width: 120px;
  height: 120px;
  -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
          border-radius: 100px;
  border: 3px solid black;
  cursor: pointer;
}

.Style_hrLineTop__Jq1yz {
  border-bottom: 1px solid #eaeaea;
  width: 935px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Style_sheetCatLabel__1_dSS {
  width: 935px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 20px;
  color: #000000;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.Style_driveContainer__3eJ69 {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Style_oneBlackName__3cUsx {
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  width: 100%;
  background-color: #000000;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.Style_expand__DBMj9 {
  cursor: pointer;
}

.Style_newDriveContain__K03ET {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  overflow-x: auto;
}

.Style_driveName__2_Ybs {
  font-family: "Mitr", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #db7c00;
  text-decoration: underline;
  text-align: center;
}

.Style_driveSubj__27vss {
  font-family: "Mitr", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  text-align: center;
}

.Style_oneDrive__1FXy5 {
  width: 146px;
}

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box-shop {
    width: 280px;
	height:34px;
    position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FFFFFF;
	box-sizing: border-box;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
}


.select-search-box-shop::after {
    font-family: fontawesome;
    content:"\f078";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    line-height: 34px;
    color: #EAEAEA;
    z-index: 1;
}

    
.select-search-box-shop__icon--disabled::after {
    content: none !important;
}

.select-search-box-shop--input::after {
    display: none !important;
}

.select-search-box-shop__out {
    display: none;
}

.select-search-box-shop__search {
    display: block;
    width: 280px;
    height: 34px;
    border: none;
    background: none;
    outline: none;
    color: #666666;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
    line-height: 34px;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
	white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
	padding-left:10px;
	padding-right:35px;
}

input.select-search-box-shop__search {
    line-height: 1;
}

.select-search-box-shop__search {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}

.select-search-box-shop--input .select-search-box-shop__search {
    cursor: text;
}

.select-search-box-shop__search:focus {
    cursor: text;
}

.select-search-box-shop__search--placeholder {
    font-weight: 500;
}

.select-search-box-shop input::-webkit-input-placeholder {
    color: #666666;
    font-weight: 400;
	line-height:34px;
}

.select-search-box-shop input::-moz-placeholder {
    color: #666666;
    font-weight: 400;
	line-height:34px;
}

.select-search-box-shop input:-moz-placeholder {
    color: #666666;
    font-weight: 400;
	line-height:34px;
}

.select-search-box-shop input:-ms-input-placeholder {
    color: #666666;
    font-weight: 400;
	line-height:34px;
}

input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

.select-search-box-shop__select {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    background: #fff;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    overflow: auto;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
	border:1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
    z-index: 100;
    min-height: 49px;
}

.select-search-box-shop__select--display {
    display: block;
}

.select-search-box-shop__options {
    padding-left:20px;
	padding-right:20px;
}

.select-search-box-shop__option {
    font-family: 'Mitr', sans-serif;
	font-weight:400;
	height:34px;
	font-size:16px;
	line-height:34px;
    color: #666666;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.select-search-box-shop__option:first-child {
    border-top: none;
}

.select-search-box-shop__option--hover, .select-search-box-shop__option:hover {
    background: #f4f7fa;
}

.select-search-box-shop__option--selected {
    background: #FAF1B5;
}

.select-search-box-shop__option--selected:hover, .select-search-box-shop__option--selected.select-search-box-shop__option--hover {
    background: #FCE279;
}

.select-search-box-shop__group {
    padding-top: 20px;
    position: relative;
}

.select-search-box-shop__group-header {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: white;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}
.Style_shopPage__2eUi5 {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_bigHeader__17Avc {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	text-align:center;
}

.Style_blanker__3rH2S {
	height:80px;
}

.Style_sellLinkLine__1dNlW {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
}

.Style_sellLinkText__4F4zs {
	font-family: 'Mitr', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color:#666666;
}

.Style_sellLinkLink__2aBWF {
	font-family: 'Mitr', sans-serif;
	font-weight: 400;
	font-size: 20px;
	margin-left:10px;
	color:#EECC1F;
}

.Style_filterBar__3Fz-J {
	margin-top:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	width:635px;
	margin-left:auto;
	margin-right:auto;
}

.Style_barMobile__3L5Xp {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.Style_barMobile__3L5Xp.Style_searcher__2AqPZ {
	width:290px;
	margin-top:40px;
}

.Style_barMobile__3L5Xp.Style_store__1bU-W {
	width:290px;
	margin-top:20px;
}

.Style_barMobile__3L5Xp.Style_filter__2rew3 {
	width:290px;
	margin-top:20px;
}

.Style_nothing__2GbeT {
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
}

.Style_sheetWrapper__31tCb {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_semSelector__2oTKG {
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #EAEAEA;
	height:34px;
	color:#666666;
	cursor:pointer;
}

.Style_semSelector__2oTKG:hover {
	background-color:#F5F5F5;
}

.Style_semSelector__2oTKG.Style_mobile__BuPTQ {
	min-width:142px;
	border:none;
}

.Style_semSelector__2oTKG.Style_mobile__BuPTQ.Style_last__IqOFv {
	min-width:142px;
	border-left:1px solid #EAEAEA;
}

.Style_search__2o1To {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border:none;
	height:34px;
	width:280px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#666666;
}

.Style_search__2o1To.Style_mobile__BuPTQ {
	border-right:none;
}

.Style_rowWrapper__1oMFq {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom:20px;
}

.Style_loader__xdFI4 {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #EECC1F;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: Style_spin__3Sx1o 2s linear infinite;
     -moz-animation: Style_spin__3Sx1o 2s linear infinite;
       -o-animation: Style_spin__3Sx1o 2s linear infinite;
          animation: Style_spin__3Sx1o 2s linear infinite;
  margin-left:auto;
  margin-right:auto;
}

@-webkit-keyframes Style_spin__3Sx1o {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes Style_spin__3Sx1o {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes Style_spin__3Sx1o {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Style_spin__3Sx1o {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_fillbot___NSQA {
	min-height:-webkit-calc(100vh - 536px);
	min-height:-moz-calc(100vh - 536px);
	min-height:calc(100vh - 536px);
}

.Style_spacebot__2TJjh {
	height:35px;
}

.Style_uniPic__2NVNS {
	width:200px;
	height:200px;
	-webkit-border-radius:100px;
	   -moz-border-radius:100px;
	        border-radius:100px;
	border:3px solid black;
	margin-left:auto;
	margin-right:auto;
	display:block;
}
.Style_description__2c89y {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	margin-top:20px;
	text-align: center;
}
.Style_dashPanel__Tlr0g {
	width:350px;
	max-width:100%;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	padding-left:16px;
	padding-right:16px;
	padding-bottom:30px;
}

.Style_header__atWXP {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:center;
	margin-top:24px;
}

.Style_textForm__ocrPY {
	display:block;
	width:100%;
	height:37px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:37px;
	padding-left:10px;
	padding-right:10px;
	color:#000000;
}

.Style_textArea__2Wxhm {
	display:block;
	width:100%;
	height:150px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:20px;
	padding-left:10px;
	padding-right:10px;
	color:#000000;
}

.Style_warn0__1TbNf {
	display:block;
	margin-left:auto;
	margin-right:auto;
	width:400px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	margin-top:10px;
	color:red;
}

.Style_warn1__2mhek {
	display:block;
	margin-left:auto;
	margin-right:auto;
	width:400px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:12px;
	margin-top:10px;
	color:red;
}

.Style_pol__2V0eo {
	color:#3399ff;
}

.Style_label__3K6N7 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:20px;
	margin-top:25px;
	margin-bottom:15px;
}

.Style_registerButton__2FRRk {
	display:block;
	width:100%;
	height:40px;
	margin-top:20px;
	color:black;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(102.91deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_registerButton__2FRRk:hover {
	opacity:0.7;
}

.Style_textCheck__5lJlG {
	margin-top:20px;
}

.Style_ack__2Ln9P {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
}

.Style_linkAck__1reki {
	color:#3399ff;
}

.Style_shopPage__3zcor {
	width:100vw;
	max-width:100%;
	min-height:-webkit-calc(100vh - 189px);
	min-height:-moz-calc(100vh - 189px);
	min-height:calc(100vh - 189px);
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_regisButton__3CrCM {
	display:block;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	height:40px;
	background-color:#ffc700;
	margin-top:10px;
	color:black;
	border:2px solid black;
	line-height:40px;
	text-align:center;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
}

.Style_topSpace__HWq8r {
	height:80px;
}

.Style_header__3pJfS {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:50px;
	text-align:center;
	color:#000000;
}
.Style_header__3pJfS.Style_mobile__Acf9a {
	font-size:40px;
}

.Style_headerMore__1JrMf {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:50px;
	text-align:center;
	color:#000000;
	margin-top:100px;
	line-height:50px;
}

.Style_headerMore__1JrMf.Style_mobile__Acf9a {
	font-size:40px;
	line-height:40px;
	margin-top:50px;
}

.Style_smallHead__1Hnkx {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	text-align:center;
	line-height:28px;
	color:#666666;
}

.Style_smallHead__1Hnkx.Style_mobile__Acf9a {
	font-size:16px;
	line-height:22px;
}

.Style_line__37jiE {
	margin-top:72px;
	border-bottom:1px solid #E2E2E2;
}

.Style_line__37jiE.Style_mobile__Acf9a {
	margin-top:36px;
	border-bottom:1px solid #E2E2E2;
}

.Style_circleRight___A7fk {
	position:absolute;
	right:0;
	top:330px;
}

.Style_circleLeft__2G1Lo {
	position:absolute;
	left:0;
	top:856px;
}

.Style_sell1Con__26IWn {
	margin-top:70px;
	height:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
}

.Style_sell1Con__26IWn.Style_mobile__Acf9a {
	height:150px;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_sellBox__1e1kj {
	width:406px;
	position:relative;
}

.Style_sellBox__1e1kj.Style_mobile__Acf9a {
	width:350px;
}

.Style_sellNumImage__lA-NE {
	position:absolute;
	top:0;
	right:0;
	height:250px;
}

.Style_sellNumImage__lA-NE.Style_mobile__Acf9a {
	height:150px;
}

.Style_sell2Con__3JJOU {
	margin-top:32px;
	height:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_sell2Con__3JJOU.Style_mobile__Acf9a {
	height:150px;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_sell3Con__3hIMR {
	margin-top:32px;
	height:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	        justify-content:flex-end;
}

.Style_sell3Con__3hIMR.Style_mobile__Acf9a {
	height:150px;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_boxHead__2uf0P {
	margin-top:75px;
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:28px;
	color:#000000;
	z-index:100;
}

.Style_boxHead__2uf0P.Style_mobile__Acf9a {
	margin-top:40px;
	font-size:22px;
}

.Style_boxTent___buVG {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#666666;
	z-index:100;
	position:relative;
}

.Style_headerFAQ__2P-p6 {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:50px;
	text-align:center;
	color:#000000;
	margin-top:100px;
}

.Style_headerFAQ__2P-p6.Style_mobile__Acf9a {
	font-size:40px;
	line-height:40px;
}

.Style_FAQblank__3Ny71 {
	height:70px;
}

.Style_FAQblank__3Ny71.Style_mobile__Acf9a {
	height:35px;
}

.Style_faqBull__qvhJZ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	color:#333333;
	cursor:pointer;
}

.Style_faqBull__qvhJZ.Style_mobile__Acf9a {
	font-size:18px;
	line-height:20px;
}

.Style_faqBull__qvhJZ:hover {
	opacity:0.7;
}

.Style_fLine__3Ddp4 {
	border-bottom:1px solid #E2E2E2;
	margin-top:16px;
	margin-bottom:36px;
}

.Style_FAQans__3_jX9 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#666666;
	margin-top:18px;
}

.Style_FAQans__3_jX9.Style_mobile__Acf9a {
	font-size:14px;
	line-height:16px;
}

.Style_bottomBlank__3IyCu {
	height:80px;
}

.Style_goLogin__10VLa {
	font-family: 'Mitr', sans-serif;
	font-size:16px;
	font-weight:500;
	width:255px;
	height:46px;
	line-height:46px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	color:#000000;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	display:block;
}

.Style_faq__35AcA {
	font-family: 'Mitr', sans-serif;
	font-size:18px;
	font-weight:500;
	margin-top:20px;
	margin-bottom:30px;
	color:#3399ff;
	display:block;
	margin-left:auto;
	margin-right:auto;
	width:120px;
	text-align:center;
}
.Style_shopPage__1a_Z6 {
	width:100vw;
	max-width:100%;
	min-height:-webkit-calc(100vh - 189px);
	min-height:-moz-calc(100vh - 189px);
	min-height:calc(100vh - 189px);
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_regisButton__2fMCm {
	display:block;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	height:40px;
	background-color:#ffc700;
	margin-top:10px;
	color:black;
	border:2px solid black;
	line-height:40px;
	text-align:center;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
}

.Style_topSpace__1FPA7 {
	height:80px;
}

.Style_header__2mC0q {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:50px;
	text-align:center;
	color:#000000;
}
.Style_header__2mC0q.Style_mobile__2WMmY {
	font-size:40px;
}

.Style_headerMore__26wxj {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:50px;
	text-align:center;
	color:#000000;
	margin-top:100px;
	line-height:50px;
}

.Style_headerMore__26wxj.Style_mobile__2WMmY {
	font-size:40px;
	line-height:40px;
	margin-top:50px;
}

.Style_smallHead__3PqcL {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	text-align:center;
	line-height:28px;
	color:#666666;
}

.Style_smallHead__3PqcL.Style_mobile__2WMmY {
	font-size:16px;
	line-height:22px;
}

.Style_line__34vHZ {
	margin-top:72px;
	border-bottom:1px solid #E2E2E2;
}

.Style_line__34vHZ.Style_mobile__2WMmY {
	margin-top:36px;
	border-bottom:1px solid #E2E2E2;
}

.Style_circleRight__1BWzX {
	position:absolute;
	right:0;
	top:330px;
}

.Style_circleLeft__eDufY {
	position:absolute;
	left:0;
	top:856px;
}

.Style_sell1Con__2JtWi {
	margin-top:70px;
	height:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
}

.Style_sell1Con__2JtWi.Style_mobile__2WMmY {
	height:150px;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_sellBox__2c3KB {
	width:406px;
	position:relative;
}

.Style_sellBox__2c3KB.Style_mobile__2WMmY {
	width:350px;
}

.Style_sellNumImage__36LlX {
	position:absolute;
	top:0;
	right:0;
	height:250px;
}

.Style_sellNumImage__36LlX.Style_mobile__2WMmY {
	height:150px;
}

.Style_sell2Con__3IfCr {
	margin-top:32px;
	height:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_sell2Con__3IfCr.Style_mobile__2WMmY {
	height:150px;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_sell3Con__1rrSn {
	margin-top:32px;
	height:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	        justify-content:flex-end;
}

.Style_sell3Con__1rrSn.Style_mobile__2WMmY {
	height:150px;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
}

.Style_boxHead__2ZA_q {
	margin-top:75px;
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:28px;
	color:#000000;
	z-index:100;
}

.Style_boxHead__2ZA_q.Style_mobile__2WMmY {
	margin-top:40px;
	font-size:22px;
}

.Style_boxTent__yba_r {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#666666;
	z-index:100;
	position:relative;
}

.Style_headerFAQ__1tEym {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:50px;
	text-align:center;
	color:#000000;
	margin-top:100px;
}

.Style_headerFAQ__1tEym.Style_mobile__2WMmY {
	font-size:40px;
	line-height:40px;
}

.Style_FAQblank__2KJHO {
	height:30px;
}

.Style_FAQblank__2KJHO.Style_mobile__2WMmY {
	height:30px;
}

.Style_faqBull__1BFyw {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	color:#333333;
	cursor:pointer;
}

.Style_faqBull__1BFyw.Style_mobile__2WMmY {
	font-size:18px;
	line-height:20px;
}

.Style_faqBull__1BFyw:hover {
	opacity:0.7;
}

.Style_fLine__3e-Nj {
	border-bottom:1px solid #E2E2E2;
	margin-top:16px;
	margin-bottom:36px;
}

.Style_FAQans__EDT0v {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#666666;
	margin-top:18px;
}

.Style_FAQans__EDT0v.Style_mobile__2WMmY {
	font-size:14px;
	line-height:16px;
}

.Style_bottomBlank__1zU7M {
	height:80px;
}

.Style_goLogin__1AMxO {
	font-family: 'Mitr', sans-serif;
	font-size:16px;
	font-weight:500;
	width:255px;
	height:46px;
	line-height:46px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	color:#000000;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	display:block;
}

.Style_typeHeader__3ZQrq {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:30px;
	color:#000000;
	margin-bottom:40px;
	line-height:30px;
	text-decoration:underline;
	margin-top:40px;
}

.Style_typeHeader__3ZQrq.Style_mobile__2WMmY {
	font-size:25px;
	text-decoration:underline;
}
.Style_shopPage__3ReHC {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#EBEBEB;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-top:100px;
}

.Style_regisButton0__vFs7h {
	display:block;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	height:40px;
	background-color:#ffc700;
	margin-top:10px;
	color:black;
	border:2px solid black;
	line-height:40px;
	text-align:center;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
}

.Style_regisButton1__2-usj {
	display:block;
	width:300px;
	margin-left:auto;
	margin-right:auto;
	height:40px;
	background-color:#ffc700;
	margin-top:10px;
	color:black;
	border:2px solid black;
	line-height:40px;
	text-align:center;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:16px;
}
.Style_shopPage__2B8jH {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_blanker__1f5Zr {
	height:80px;
}


.Style_bigHeader__2Kx1O {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	text-align:center;
}

.Style_header__1dfwe {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:25px;
	text-align:center;
	margin-top:30px;
}

.Style_newButton__lYfIO {
	margin-top:30px;
	font-family: 'Mitr', sans-serif;
	font-size:16px;
	font-weight:500;
	width:255px;
	height:46px;
	line-height:46px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	color:#000000;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	display:block;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_newButton__lYfIO:hover {
	opacity:0.7;
}

.Style_nothing__gZlQx {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_instruct__pNTOS {
	margin-top:20px;
	color:#666666;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sellID__3uoG2 {
	margin-top:10px;
	color:#333333;
	opacity:0.6;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_unverf__18pzw {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background-color:white;
	height:40px;
	width:100%;
	border-bottom:1px solid #DDDDDD;
	margin-top:5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
}

.Style_unverf__18pzw:hover {
	background-color: #EFEFEF;
}

.Style_unverfName__3OVVh {
	color:black;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	width:-webkit-calc(100% - 120px);
	width:-moz-calc(100% - 120px);
	width:calc(100% - 120px);
}

.Style_statusButt0__1KM19 {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#3399FF;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_statusButt1__2bpXM {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#EECC1F;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_statusButt2__2w8Hp {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#EECC1F;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_statusButt3__IGsqi {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#FF0000;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_sheetWrapper__Bt_g1 {
	margin-top:35px;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_semSelector__3HAil {
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #EAEAEA;
	height:34px;
	color:#666666;
	cursor:pointer;
}

.Style_semSelector__3HAil:hover {
	background-color:#F5F5F5;
}

.Style_semSelector__3HAil.Style_mobile__pteWQ {
	min-width:142px;
	border:none;
}

.Style_semSelector__3HAil.Style_mobile__pteWQ.Style_last__2sGPo {
	min-width:142px;
	border-left:1px solid #EAEAEA;
}

.Style_semSelector__3HAil.Style_mobile__pteWQ.Style_sort__gcP0F {
	width:100%;
}

.Style_search__27xER {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border:none;
	height:34px;
	width:280px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#666666;
}

.Style_search__27xER.Style_mobile__pteWQ {
	border-right:none;
}

.Style_rowWrapper__3xS8R {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_barMobile__2mvs0 {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.Style_barMobile__2mvs0.Style_searcher__1VsBz {
	width:290px;
	margin-top:40px;
}

.Style_barMobile__2mvs0.Style_filter__25EyV {
	width:290px;
	margin-top:20px;
}

.Style_filterBar__2leSc {
	margin-top:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	width:550px;
	margin-left:auto;
	margin-right:auto;
}
.Style_dashPanel__3ysyy {
	width:350px;
	max-width:100%;
	background: #FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	padding-left:16px;
	padding-right:16px;
	padding-bottom:30px;
}

.Style_header__1pAAM {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:30px;
	text-align:center;
	margin-top:24px;
}

.Style_textForm__12cyD {
	display:block;
	width:100%;
	height:37px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:37px;
	padding-left:10px;
	padding-right:10px;
	color:#000000;
}

.Style_textArea__1gH15 {
	display:block;
	width:100%;
	height:150px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:20px;
	padding-left:10px;
	padding-right:10px;
	color:#000000;
}

.Style_warn0__1dkVG {
	display:block;
	margin-left:auto;
	margin-right:auto;
	width:400px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	margin-top:10px;
	color:red;
}

.Style_warn1__21e3O {
	display:block;
	margin-left:auto;
	margin-right:auto;
	width:400px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:12px;
	margin-top:10px;
	color:red;
}

.Style_pol__15GrU {
	color:#3399ff;
}

.Style_label__2j-c4 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:20px;
	margin-top:25px;
	margin-bottom:15px;
}

.Style_registerButton__3oyoP {
	display:block;
	width:100%;
	height:40px;
	margin-top:20px;
	color:black;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(347.09deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(102.91deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	cursor:pointer;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_registerButton__3oyoP:hover {
	opacity:0.7;
}
.Style_sheetPage__3ijU4 {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__3HWO_ {
	height:80px;
}

.Style_container__Ly_sv {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__3SHCV {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__38gmN {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_label__2jA_r {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}


.Style_explainLabel__1_pAx {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_textField__3jQuX {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__IIM7Y {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__ltMdy {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__U2Z9B {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__39_K1 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__1b0Qi {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__331Ud {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__1Fy65 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__3Kt20 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__kATnl {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:50px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__kATnl:hover {
	opacity:0.7;
}

.Style_sendButton__wRj0E {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:20px;
	margin-bottom:30px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__wRj0E:hover {
	opacity:0.7;
}

.Style_buttContainer__wXWbd {
	height:120px;
}

.Style_smallLoader__YKNs3 {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__AWWS- 2s linear infinite;
	   -moz-animation: Style_spin__AWWS- 2s linear infinite;
	     -o-animation: Style_spin__AWWS- 2s linear infinite;
	        animation: Style_spin__AWWS- 2s linear infinite;
}

.Style_reject__2CELi {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__X3Q42 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__2pMgU {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__3t3VD {
	display:block;
	width:100%;
	height:19px;
}

.Style_redText__EwDn4 {
	margin-top:200px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:red;
}

.Style_hardEditButton__3h72b {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	margin-bottom:50px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #FF0000;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_hardEditButton__3h72b:hover {
	opacity:0.7;
}


@-webkit-keyframes Style_spin__AWWS- {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__AWWS- {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__AWWS- {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__AWWS- {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}
.Style_sheetPage__NB6IP {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__iKTOx {
	height:80px;
}

.Style_container__1DZLP {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__Jdl4U {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__WVFcz {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_label__1a2sT {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}


.Style_explainLabel__v0DHc {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_textField__1S8kZ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__3z3vZ {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__2bqc_ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__2NInG {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__11g0J {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__2rY_a {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__1Avrp {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__3WyIm {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__2Rfs_ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__1a55b {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:50px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__1a55b:hover {
	opacity:0.7;
}

.Style_sendButton__3H15c {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:20px;
	margin-bottom:30px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__3H15c:hover {
	opacity:0.7;
}

.Style_buttContainer__3V1d_ {
	height:120px;
}

.Style_smallLoader__2jFC8 {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__2z4eF 2s linear infinite;
	   -moz-animation: Style_spin__2z4eF 2s linear infinite;
	     -o-animation: Style_spin__2z4eF 2s linear infinite;
	        animation: Style_spin__2z4eF 2s linear infinite;
}

.Style_reject__2poQb {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__28Y2t {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__u7SsP {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__3fl62 {
	display:block;
	width:100%;
	height:19px;
}

.Style_redText___c_Se {
	margin-top:200px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:red;
}

.Style_hardEditButton__2TkTe {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	margin-bottom:50px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #FF0000;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_hardEditButton__2TkTe:hover {
	opacity:0.7;
}


@-webkit-keyframes Style_spin__2z4eF {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__2z4eF {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__2z4eF {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__2z4eF {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_recText__3DftZ {
	margin-top:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align: center;
	color:#000000;
}

.Style_recAmount__3kLTY {
	margin-top:10px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align: center;
	color:#00aa00;
	margin-bottom:20px;
}
.Style_wrapper__W-sEj {
	width:180px;
	min-height:200px;
	background:transparent;
	margin-left:10px;
	margin-right:10px;
	margin-bottom:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_cover__270VC {
	width:178px;
	height:178px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.Style_starRevBig__R9kyG {
	height:20px;
	position:relative;
	top:4px;
}

.Style_star__1HjSy {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:8px;
	color:black;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
	position:relative;
}

.Style_sold__3axwr {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:10px;
	color:#000000;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_addCart__3V2wj {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_wrapperMobile__2ZKUT {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	width:100%;
	height:170px;
	margin-top:20px;
	border-bottom:1px solid #AAAAAA;
}
.Style_coverMobile__xLijD {
	width:148px;
	height:148px;
}
.Style_starMobile__3j4vo {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:black;
	position:relative;
}

.Style_soldMobile__1Nrmp {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:#000000;
	margin-top:10px;
}

.Style_addCartMobile__omNMW {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_infoMobile__3EXi0 {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	cursor:pointer;
}
.Style_shopPage__1L38V {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_blanker__15rMP {
	height:80px;
}


.Style_bigHeader__f_OHU {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	text-align:center;
}

.Style_header__2VxWf {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:25px;
	text-align:center;
	margin-top:30px;
}

.Style_newButton__10bPC {
	margin-top:30px;
	font-family: 'Mitr', sans-serif;
	font-size:16px;
	font-weight:500;
	width:255px;
	height:46px;
	line-height:46px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	color:#000000;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	display:block;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_newButton__10bPC.Style_den__sfxKE {
	border: 2px solid black;
	color:#FFFFFF;
	background:none;
	background-color:#00AA00;
}

.Style_newButton__10bPC.Style_redder__3G1qj {
	border: 2px solid black;
	color:#FFFFFF;
	background:none;
	background-color:rgb(182, 43, 64);
}

.Style_newButton__10bPC:hover {
	opacity:0.7;
}

.Style_nothing__3VmCc {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_instruct__1SMfy {
	margin-top:20px;
	color:#666666;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sellID__1wXJe {
	margin-top:10px;
	color:#333333;
	opacity:0.6;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_unverf__12dGl {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background-color:white;
	height:40px;
	width:100%;
	border-bottom:1px solid #DDDDDD;
	margin-top:5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
}

.Style_unverf__12dGl:hover {
	background-color: #EFEFEF;
}

.Style_unverfName__7dJgr {
	color:black;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	width:-webkit-calc(100% - 120px);
	width:-moz-calc(100% - 120px);
	width:calc(100% - 120px);
}

.Style_statusButt0__w3fmr {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#3399FF;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_statusButt1__1Joko {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#EECC1F;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_statusButt2__bo3fs {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#EECC1F;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_statusButt3__2-pkB {
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	line-height:25px;
	color:#FF0000;
	width:112px;
	min-width:112px;
	max-width:112px;
	text-align:right;
	font-size:14px;
	padding-right:10px;
}

.Style_sheetWrapper__2pEDZ {
	margin-top:35px;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_semSelector__35HVE {
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #EAEAEA;
	height:34px;
	color:#666666;
	cursor:pointer;
}

.Style_semSelector__35HVE:hover {
	background-color:#F5F5F5;
}

.Style_semSelector__35HVE.Style_mobile__1giL7 {
	min-width:142px;
	border:none;
}

.Style_semSelector__35HVE.Style_mobile__1giL7.Style_last__QLcLh {
	min-width:142px;
	border-left:1px solid #EAEAEA;
}

.Style_semSelector__35HVE.Style_mobile__1giL7.Style_sort__3iy5P {
	width:100%;
}

.Style_search__1kLjJ {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border:none;
	height:34px;
	width:280px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#666666;
}

.Style_search__1kLjJ.Style_mobile__1giL7 {
	border-right:none;
}

.Style_rowWrapper__2S5DC {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_barMobile__3daM_ {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.Style_barMobile__3daM_.Style_searcher__3ODyP {
	width:290px;
	margin-top:40px;
}

.Style_barMobile__3daM_.Style_filter__33KxH {
	width:290px;
	margin-top:20px;
}

.Style_filterBar__2yNJr {
	margin-top:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	width:550px;
	margin-left:auto;
	margin-right:auto;
}
.Style_shopPage__2motw {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_blanker__22HZF {
	height:80px;
}

.Style_sellDataHead__mJVbr {
	margin-top:20px;
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	text-align:center;
}

.Style_sellData__3dodG {
	margin-top:5px;
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	text-align:center;
}

.Style_sellDataend__2Hmzm {
	margin-top:5px;
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	text-align:center;
	margin-bottom:25px;
}


.Style_bigHeader__1AenX {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	text-align:center;
}

.Style_header__2qCDa {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:25px;
	text-align:center;
	margin-top:30px;
}

.Style_newButton__2mDQz {
	margin-top:30px;
	font-family: 'Mitr', sans-serif;
	font-size:16px;
	font-weight:500;
	width:255px;
	height:46px;
	line-height:46px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	color:#000000;
	cursor:pointer;
	margin-left:auto;
	margin-right:auto;
	display:block;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_newButton__2mDQz:hover {
	opacity:0.7;
}

.Style_nothing__2piPd {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_instruct__1HUOu {
	margin-top:20px;
	color:#666666;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sellID__iginK {
	margin-top:10px;
	color:#333333;
	opacity:0.6;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_payBox__2X3vX {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:2px solid black;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	width:350px;
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:20px;
	padding-top:10px;
	padding-bottom:10px;
	padding-left:10px;
	padding-right:10px;
}

.Style_payHead__I2wCg {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:18px;
	text-align:center;
}

.Style_greenText__19yPL {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:18px;
	text-align:center;
	color:#00AA00;
	margin-top: 20px;
}

.Style_payMoney__1_1UI {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:25px;
	text-align:center;
	color:#3399ff;
}

.Style_paySlip__2zzUd {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	text-align:center;
	color:#ff0000;
}

.Style_payToggle__11OHP {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	text-align:center;
	color:#000000;
	cursor:pointer;
	margin-top:20px;
}

.Style_payMoneyHead__Jxm9f {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	text-align:center;
	color:#000000;
	margin-top:5px;
}

.Style_payMoneyHead__Jxm9f {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	text-align:center;
	color:#000000;
	margin-top:5px;
}

.Style_payMoneyImage__-Dwk_ {
	width:200px;
	height:200px;
	margin-left:auto;
	margin-right:auto;
	display: block;
}

.Style_paySubHead__3zUdK {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color:#000000;
	margin-top:10px;
}

.Style_paySubText__BEPQi {
	font-weight:500;
	font-size:18px;
	color:firebrick;
	margin-top:10px;
	display: inline-block;
	margin-left: 10px;
}

.Style_payMessage__3APq- {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#000000;
	margin-top:5px;
	border:1px solid black;
	padding-left:10px;
	padding-right:10px;
	padding-top:5px;
	padding-bottom:5px;
	height:100px;
	word-break: break-all;
	overflow-y: scroll;
	-o-text-overflow: wrap;
	   text-overflow: wrap;
	background-color:#eeeeee;
}

.Style_sheetHead__1IH7m {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sheetInfoCon__3xxo- {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	        justify-content:space-between;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
}

.Style_payContainer__1ToiS {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
}

.Style_yellowButton__1CqDC {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	cursor:pointer;
}

.Style_greenButton__1i71i {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	margin-top:10px;
	background:;
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	border: 2px solid black;
	color:#FFFFFF;
	background:none;
	background-color:#00AA00;
	cursor:pointer;
}

.Style_reportBox__2B83s {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:250px;
	position:fixed;
	top:-webkit-calc(50vh - 125px);
	top:-moz-calc(50vh - 125px);
	top:calc(50vh - 125px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_rClose__XPXNZ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_rHeader__28es9 {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_rAdd__2a2gr {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_rAddRed__2iGeJ {
	background-color:#00AA00;
	color:#ffffff;
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_tAreaLong__3uajJ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#000000;
	width:100%;
	height:110px;
	border:1px solid black;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid #dadada;
	padding-left:3px;
	padding-right:3px;
}

.Style_rHeader__28es9 {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_rLabel__2jpT8 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#000000;
}

.Style_filterButtonGroup__1umyn {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
}

.Style_filterButton__2Hzv7 {
	background: #ffffff;
	height:30px;
	width:150px;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	border:1px solid black;
}

.Style_filterButton__2Hzv7.Style_right__3hTn0 {
	border-left: none;
}

.Style_filterButton__2Hzv7.Style_active__1Jqzn{
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
}

.Style_shopPage__cJ2pX {
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.Style_blanker__1FnuX {
  height: 80px;
}

.Style_sellDataHead__i7f0R {
  margin-top: 20px;
  font-family: "Mitr", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.Style_sellData__199dE {
  margin-top: 5px;
  font-family: "Mitr", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}

.Style_sellDataend__39_nr {
  margin-top: 5px;
  font-family: "Mitr", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  margin-bottom: 25px;
}

.Style_bigHeader__3Da1d {
  font-family: "Mitr", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
}

.Style_header__3Fflk {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
}

.Style_newButton__1Srzd {
  margin-top: 30px;
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 255px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: -webkit-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -moz-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: -o-linear-gradient(349.77deg, #ffe992 0%, #ffcd09 100%);
  background: linear-gradient(100.23deg, #ffe992 0%, #ffcd09 100%);
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  color: #000000;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  display: block;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.Style_newButton__1Srzd:hover {
  opacity: 0.7;
}

.Style_nothing__5nbNU {
  margin-top: 20px;
  color: #bdbdbd;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Style_instruct__3EBab {
  margin-top: 20px;
  color: #666666;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Style_sellID__tOWiE {
  margin-top: 10px;
  color: #333333;
  opacity: 0.6;
  text-align: center;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Style_payBox__3199U {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid black;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  width: 350px;
  min-height: 273px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.Style_payHead__1OZHf {
  font-family: "Mitr", sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.Style_payMoney__1fH2B {
  font-family: "Mitr", sans-serif;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #3399ff;
}

.Style_paySlip__1D9CV {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ff0000;
}

.Style_payToggle__JooDw {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  margin-top: 20px;
}

.Style_payMoneyHead__Wuaje {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
}

.Style_sheetHead__1XzC2 {
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Style_sheetInfoCon__1GUJ1 {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.Style_payContainer__2SMz0 {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.Style_page__OrfZd {
	background-color: #48267b;
	width:100vw;
	max-width:100%;
	min-height:100vh;
	padding-top: 30px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_header__1bynE {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:22px;
	text-align:center;
	color: #FFFFFF;
}

.Style_slipWrapper__6Lzq3 {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-top:50px;
}

.Style_slipBox__3DzgP {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:350px;
	height: 570px;
	background-color: #ffffff;
	margin-left:20px;
	margin-right:20px;
	margin-top:20px;
	margin-bottom:20px;
	padding-top:20px;
	padding-bottom:20px;
}

.Style_name__2Zrb6 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	color: #000000;
	padding-left:20px;
}

.Style_amount__26HOk {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color: #000000;
	padding-left:20px;
}

.Style_date__3EUDe {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:13px;
	color: #666666;
	margin-top:10px;
	padding-left:20px;
}

.Style_success__1ZF03 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color: #ffffff;
	margin-top:10px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	background-color: #28ae7b;
	width:80px;
	text-align:center;
	height:20px;
	line-height:20px;
	-webkit-border-radius:10px;
	   -moz-border-radius:10px;
	        border-radius:10px;
}

.Style_success2__1e-Fv {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:13px;
	color: #666666;
	margin-top:5px;
	text-align:center;
}

.Style_success3__1GJRK {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:15px;
	color: #000000;
	margin-top:10px;
	text-align:center;
}

.Style_behead__2Uev8 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:13px;
	color: #666666;
	background-color:#dddddd;
	margin-top:10px;
	padding-left:20px;
}

.Style_besubhead__2zT2K {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:11px;
	color: #888888;
	padding-left:20px;
}

.Style_bedata__1Cqfi {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:15px;
	color: #000000;
	border-bottom: 1px solid #dddddd;
	padding-left:20px;
}

.Style_warning__37rmQ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color: #ffa500;
	margin-top:5px;
	text-align:center;
}

.Style_danger__2hggt {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color: #ff0000;
	margin-top:5px;
	text-align:center;
}
.Style_wrapper__3YkPN {
	width:180px;
	min-height:200px;
	background:transparent;
	margin-left:10px;
	margin-right:10px;
	margin-bottom:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_cover__10IPX {
	width:178px;
	height:178px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.Style_star__2Kmiv {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:8px;
	color:black;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_sold__1ggFN {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:10px;
	color:#000000;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_addCart__1kR5Q {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:not-allowed;
	opacity:0.3;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_addCartAvail__2Ckg- {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_report__3pWkD {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ffffff;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background-color:#ff0000; 
	-webkit-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45); 
	   -moz-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45); 
	        box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_wrapperMobile__1Co7o {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	width:100%;
	height:170px;
	margin-top:20px;
	border-bottom:1px solid #AAAAAA;
}
.Style_coverMobile__1cmjM {
	width:148px;
	height:148px;
}
.Style_starMobile__1yurL {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:black;
}

.Style_soldMobile__1BFEf {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:#000000;
	margin-top:10px;
}

.Style_addCartMobile__b3w4f {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:not-allowed;
	opacity:0.3;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_infoMobile__1vhLR {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_reviewMobile__37kcV {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-left:20px;
	margin-top:15px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_reportMobile__T3Jm6 {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#ffffff;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-left:20px;
	margin-top:15px;
	background-color: #ff0000;
	-webkit-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}
.Style_shopPage__1ylrd {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_blanker__1E0Zn {
	height:80px;
}

.Style_bigHeader__1r7al {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	text-align:center;
}

.Style_nothing__3lRsC {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sheetWrapper__3jRSp {
	margin-top:35px;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_semSelector__dhcmk {
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #EAEAEA;
	height:34px;
	color:#666666;
	cursor:pointer;
	background-color:#ffffff;
}

.Style_semSelector__dhcmk:hover {
	background-color:#F5F5F5;
}

.Style_semSelector__dhcmk.Style_mobile__1txJ- {
	min-width:142px;
	border:none;
}

.Style_semSelector__dhcmk.Style_mobile__1txJ-.Style_last__13AN5 {
	min-width:142px;
	border-left:1px solid #EAEAEA;
}

.Style_semSelector__dhcmk.Style_mobile__1txJ-.Style_sort__3uNgu {
	width:100%;
}

.Style_search__2kRN4 {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border:none;
	height:34px;
	width:280px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#666666;
	background-color:#ffffff;
}

.Style_search__2kRN4.Style_mobile__1txJ- {
	border-right:none;
}

.Style_rowWrapper__2RJ2x {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_barMobile__3SA7e {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.Style_barMobile__3SA7e.Style_searcher__38H5T {
	width:290px;
	margin-top:40px;
}

.Style_barMobile__3SA7e.Style_filter__10sY_ {
	width:290px;
	margin-top:20px;
}

.Style_filterBar__2apN4 {
	margin-top:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	width:420px;
	margin-left:auto;
	margin-right:auto;
}

.Style_reject__WpLpM {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__32nPb {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__2wChX {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_reviewBox__1hi0l {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:320px;
	position:fixed;
	top:-webkit-calc(50vh - 160px);
	top:-moz-calc(50vh - 160px);
	top:calc(50vh - 160px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_reviewBoxShort__YFUSb {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:270px;
	position:fixed;
	top:-webkit-calc(50vh - 160px);
	top:-moz-calc(50vh - 160px);
	top:calc(50vh - 160px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_reportBox__1QA9R {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:320px;
	position:fixed;
	top:-webkit-calc(50vh - 160px);
	top:-moz-calc(50vh - 160px);
	top:calc(50vh - 160px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_rClose__1C1zo {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_rHeader__27Pxx {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_rAdd__2Tkl- {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_rAddRed__2NECw {
	background-color:#ff0000;
	color:#ffffff;
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_rErase__13LIa {
	background: #EAEAEA;
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ff0000;
	margin-top:10px;
}

.Style_bGroup__24z5k {
	position:absolute;
	bottom:10px;
	left:10px;
	width:280px;
}

.Style_rLabel__14GNa {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#000000;
}

.Style_tArea__5DduF {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	width:100%;
	height:110px;
	border:1px solid black;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid #dadada;
	padding-left:3px;
	padding-right:3px;
}

.Style_tAreaLong__3YT1N {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#000000;
	width:100%;
	height:110px;
	border:1px solid black;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid #dadada;
	padding-left:3px;
	padding-right:3px;
}

.Style_tSelect__2uI6J {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#000000;
	width:100%;
	height:30px;
	border:1px solid black;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid #dadada;
	padding-left:3px;
	padding-right:3px;
	background-color: #ffffff;
}

.Style_boxG__NzHJ8 {
	height:23px;
	border:1px solid #dadada;
	width:100%;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	margin-bottom:5px;
	margin-top:5px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	cursor:pointer;
}

.Style_star__CEzCv {
	height:15px;
}

.Style_box1__23phk {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:23px;
	border-right:1px solid #dadada;
}
.Style_box2__1BL8c {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:42px;
	border-right:1px solid #dadada;
}
.Style_box3__3IQeR {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:58px;
	border-right:1px solid #dadada;
}
.Style_box4__3Hm2E {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:74px;
	border-right:1px solid #dadada;
}
.Style_box5__1_zKK {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:86px;
}

.Style_sBox__1P_KY {
	background: #000000;
}

.Style_rsn__1zBKS {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#000000;
	height:15px;
	overflow:hidden;
}
.Style_contain__MqvAd {
	margin-top:20px;
}

.Style_lineCon__1I_P5 {
	height:50px;
	width:100%;
	border-bottom:1px solid black;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:space-between;
	   -moz-box-pack:justify;
	        justify-content:space-between;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
}

.Style_name__1aqdl {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	cursor:pointer;
	width:-webkit-calc(100% - 180px);
	width:-moz-calc(100% - 180px);
	width:calc(100% - 180px);
	height:30px;
	line-height:30px;
	overflow:hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
}

.Style_editButton__11m3- {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:80px;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
}

.Style_deleteButton__29M1t {
	background: red;
	color:#ffffff;
	height:30px;
	width:80px;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
}

.Style_nothing__3ZjZi {
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sheetWrapper__eXv27 {
	margin-top:35px;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.Style_wrapper__HDR0U {
	width:180px;
	min-height:200px;
	background:transparent;
	margin-left:10px;
	margin-right:10px;
	margin-bottom:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_cover__28WmP {
	width:178px;
	height:178px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.02);
	cursor:pointer;
}

.Style_starRevBig__1a6Qs {
	height:20px;
	position:relative;
	top:4px;
}
.Style_star__1uWn7 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:8px;
	color:black;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
	position:relative;
}

.Style_sold__3xBHa {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:10px;
	color:#000000;
	padding-right:10px;
	margin-top:10px;
	text-align:center;
}

.Style_addCart__4oI2m {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_removeFavorite__15OsD {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:100%;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background-color: red;
	-webkit-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	color:#ffffff;
}

.Style_wrapperMobile__tVEUM {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	width:100%;
	height:170px;
	margin-top:20px;
	border-bottom:1px solid #AAAAAA;
}
.Style_coverMobile__1WgIP {
	width:148px;
	height:148px;
	cursor:pointer;
}
.Style_starMobile__Y2Lzy {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:black;
	position:relative;
}

.Style_soldMobile__14O3y {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:13px;
	padding-left:20px;
	color:#000000;
	margin-top:10px;
}

.Style_addCartMobile__3QFfH {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_removeFavoriteMobile__5Sxaq {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background-color: red;
	-webkit-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 0, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	color:#ffffff;
}

.Style_infoMobile__GJY3T {
	background-color:black;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:150px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:15px;
	margin-left:20px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}
.Style_shopPage__2N5Nd {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_blanker__1bn9V {
	height:80px;
}

.Style_bigHeader__29yMK {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	text-align:center;
}

.Style_nothing__ZKI8Z {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_sheetWrapper__33Keh {
	margin-top:35px;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_addFavoriteBox__3ftw2 {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:175px;
	position:fixed;
	top:-webkit-calc(50vh - 88px);
	top:-moz-calc(50vh - 88px);
	top:calc(50vh - 88px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_fAdd__3BWiu {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_fAdd__3BWiu.Style_inactive__2twO7 {
	opacity:0.3;
	cursor:not-allowed;
}

.Style_bGroup__2Y3YN {
	position:absolute;
	bottom:10px;
	left:10px;
	width:280px;
}

.Style_fHeader__2sYdi {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_fLabel__1iyyz {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_addFavText__ol2I8 {
	width:100%;
	height:30px;
	border:1px solid #cccccc;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:10px;
	padding-right:10px;
}

.Style_fClose__rAIpm {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}
.Style_Page__aM9G4 {
	width:100vw;
}

.Style_loader__vadPv {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: Style_spin__1K2AP 2s linear infinite;
     -moz-animation: Style_spin__1K2AP 2s linear infinite;
       -o-animation: Style_spin__1K2AP 2s linear infinite;
          animation: Style_spin__1K2AP 2s linear infinite;
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin-top:70px;
}

.Style_center__3awCd {
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	color:#333333;
	margin-top:20px;
}

@-webkit-keyframes Style_spin__1K2AP {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes Style_spin__1K2AP {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes Style_spin__1K2AP {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Style_spin__1K2AP {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_banner335__3IYBA {
  width:100%;
	max-width:335px;
}

.Style_banner700__1ebd_ {
	width:100%;
  max-width:700px;
}

.Style_containn__2_9DU {
	margin-left:auto;
	margin-right:auto;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	width:100%;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
  margin-top:20px;
}
.Style_sheetPage__2VaD7 {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__2fgbS {
	height:80px;
}

.Style_container__1jmoM {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__iqEoh {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__2VYZ2 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_redexplain__3Uafa {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#ff0000;
	text-align:center;
}

.Style_label__1lmCZ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}


.Style_explainLabel__35y1a {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_explainLabelRed__31l7s {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#ff0000;
	margin-top:5px;
}

.Style_explainLabelExtra__3N7MO {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	        justify-content: flex-start;
}

.Style_qimage__1E5dV {
	width: 200px;
	height: 20px;
	cursor: pointer;
	position: relative;
	color: #ff0000;
	text-decoration:underline;
}

.Style_qcontain__1iJoc {
	position: relative;
}

.Style_textField__2DnWp {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__Eamrq {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__19UvE {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__b8czC {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__2wsiX {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__Zgf38 {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__2me9j {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__3kqqI {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__3pB0t {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__D9g4P {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__D9g4P:hover {
	opacity:0.7;
}

.Style_sendButton__GTz5e {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__GTz5e:hover {
	opacity:0.7;
}

.Style_buttContainer__HqtIb {
	height:120px;
}

.Style_smallLoader__3Ojvt {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__1mHST 2s linear infinite;
	   -moz-animation: Style_spin__1mHST 2s linear infinite;
	     -o-animation: Style_spin__1mHST 2s linear infinite;
	        animation: Style_spin__1mHST 2s linear infinite;
}

.Style_reject__1k6r0 {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__3Lgcm {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__EXuX_ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__3ua-B {
	display:block;
	width:100%;
	height:19px;
}


@-webkit-keyframes Style_spin__1mHST {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__1mHST {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__1mHST {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__1mHST {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_qclasspic__1Wj_N {
	width:300px;
	position: absolute;
	left:100px;
}

.Style_qdetailpic__AW5UL {
	width:400px;
	position: absolute;
	left:100px;
}

.Style_calLink__1kU2g {
	color: #ff0000;
	text-decoration:underline;
}

.Style_choicebox__BJiyd {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
}

.Style_choiceboxchild__1RjK_ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#000000;
	border:2px solid #3399ff;
	width:100px;
	text-align:center;
	cursor:pointer;
	background-color: #e0ffff;
	margin-top:5px;
}

.Style_banner__2zn-7 {
	width:100%;
	border: 3px solid #ff0000;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-top: 10px;
}
[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box-editSheet {
	margin-top:10px;
    width: 100%;
	height:40px;
    position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}


    .select-search-box-editSheet::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #222f3e;
        z-index: 1;
    }

    
    .select-search-box-editSheet__icon--disabled::after {
        content: none !important;
    }

    .select-search-box-editSheet--input::after {
        display: none !important;
    }

    .select-search-box-editSheet__out {
        display: none;
    }

    .select-search-box-editSheet__search {
        display: block;
        width: 100%;
        height: 40px;
        border: none;
        background: none;
        outline: none;
        color: #222f3e;
        -webkit-appearance: none;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        position: relative;
         z-index: 2;
		cursor: pointer;
		font-family: 'Mitr', sans-serif;
		font-weight:400;
		font-size:16px;
		line-height: 34px;
		-webkit-box-shadow: none;
		   -moz-box-shadow: none;
		        box-shadow: none;
		white-space: nowrap;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		   text-overflow: ellipsis;
		padding-left:10px;
		padding-right:35px;
    }

    input.select-search-box-editSheet__search {
        line-height: 1;
    }

    .select-search-box-editSheet__search {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    .select-search-box-editSheet--input .select-search-box-editSheet__search {
        cursor: text;
    }

        .select-search-box-editSheet__search:focus {
            cursor: text;
        }

        .select-search-box-editSheet__search--placeholder {
            font-weight: 500;
        }

    .select-search-box-editSheet input::-webkit-input-placeholder {
        color: #666666;
        font-weight: 400;
		line-height:40px;
    }

    .select-search-box-editSheet input::-moz-placeholder {
        color: #666666;
        font-weight: 400;
		line-height:40px;
    }

    .select-search-box-editSheet input:-moz-placeholder {
        color: #666666;
        font-weight: 400;
		line-height:40px;
    }

    .select-search-box-editSheet input:-ms-input-placeholder {
        color: #666666;
        font-weight: 400;
		line-height:30px;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box-editSheet__select {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        background: #fff;
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        overflow: auto;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
		border:1px solid #EAEAEA;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
        z-index: 100;
        min-height: 49px;
    }

        .select-search-box-editSheet__select--display {
            display: block;
        }

    .select-search-box-editSheet__option {
        font-family: 'Mitr', sans-serif;
		font-weight:400;
		height:40px;
		font-size:16px;
		line-height:40px;
		color: #666666;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		   text-overflow: ellipsis;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
    }
	
	.select-search-box-editSheet__options {
		padding-left:20px;
		padding-right:20px;
	}

        .select-search-box-editSheet__option:first-child {
            border-top: none;
        }

        .select-search-box-editSheet__option--hover, .select-search-box-editSheet__option:hover {
            background: #f4f7fa;
        }

        .select-search-box-editSheet__option--selected {
            background: #FAF1B5;
        }

            .select-search-box-editSheet__option--selected:hover, .select-search-box-editSheet__option--selected.select-search-box-editSheet__option--hover {
                background: #FCE279;
            }

        .select-search-box-editSheet__group {
            padding-top: 20px;
			position:relative;
        }

        .select-search-box-editSheet__group-header {
            position: absolute;
			top: 0;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			   -moz-transform: translate(-50%, -50%);
			     -o-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
			background: white;
			padding: 0 10px;
			color: rgba(0, 0, 0, 0.5);
			font-size: 12px;
        }
.Style_shopPage__ZRQRN {
	width:100vw;
	max-width:100%;
	min-height:-webkit-calc(100vh - 189px);
	min-height:-moz-calc(100vh - 189px);
	min-height:calc(100vh - 189px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_topBlank__2TguF {
	height:80px;
}

.Style_bigheader__2-5tJ {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:30px;
	text-align:center;
}

.Style_sheetCover__2gBlK {
	width:100px;
	height:100px;
}

.Style_sheetContain__383z5 {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-top:45px;
}

.Style_sheetLeft__6n2-N {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	        justify-content: flex-start;
}

.Style_sheetRight__1gxrq {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-align-items: flex-end;
	   -moz-box-align: end;
	        align-items: flex-end;
	min-width:100px;
}

.Style_sheetLeftInfo__m151W {
	margin-left:20px;
}

.Style_sheetName__6wPl9 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:left;
	color:#333333;
	line-height:20px;
	word-break: break-all;
}

.Style_sheetAuth__2pVRB {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	text-align:left;
	color:#666666;
	margin-top:10px;
	word-break: break-all;
}

.Style_sheetPrice__17kQi {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:right;
	color:#333333;
}

.Style_sheetDelete__6akbZ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#E15050;
	cursor:pointer;
	text-align:right;
	line-height:16px;
}

.Style_noCart__CO-rK {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:30px;
	text-align:center;
	color:#000000;
	line-height:20px;
}

.Style_noCartButt__3IIu8 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#EECC1F;
	cursor:pointer;
	text-align:center;
	margin-top:20px;
}

.Style_totalPrice__2FtOM {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	margin-top:50px;
	text-align:center;
	color:#000000;
}

.Style_greenTotalPrice__3HCb1 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	margin-top:50px;
	text-align:center;
	color:green;
}

.Style_goButt__2wMQy {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color:#333333;
	height:40px;
	width:200px;
	line-height:40px;
	margin-left:auto;
	margin-right:auto;
	margin-top:30px;
	margin-bottom:60px;
	text-align:center;
	cursor:pointer;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_loginButt__vLhhf {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color:#333333;
	height:40px;
	width:250px;
	line-height:40px;
	margin-left:auto;
	margin-right:auto;
	margin-top:30px;
	margin-bottom:60px;
	text-align:center;
	cursor:pointer;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
}

.Style_noContainer__13nEN {
	margin-top:80px;
	margin-bottom:140px;
	width:320px;
	height:247px;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.Style_noCartLogo__27x_H {
	margin-top:32px;
	width:90px;
	height:90px;
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	   -moz-box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	        box-shadow: 0px 5px 24px rgba(255, 205, 9, 0.2);
	margin-left:auto;
	margin-right:auto;
	-webkit-border-radius:100px;
	   -moz-border-radius:100px;
	        border-radius:100px;
	text-align:center;
}

.Style_noCartLogoSVG__2H5Gg {
	width:30px;
	height:30px;
	margin-top:30px;
}

.Style_promoContainer__23VoH {
	margin-top:50px;
	width:250px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	margin-left:auto;
	margin-right:auto;
}

.Style_promoHead__1fqrj {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:15px;
	color:#333333;
}

.Style_promoFill__3uR2d {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:15px;
	width:80px;
	height:30px;
	color:#333333;
	border:1px solid black;
	margin-left:5px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding:5px;
}

.Style_checkPromo__36m_z {
	height:30px;
	line-height:30px;
	width:50px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	border:1px solid black;
	text-align:center;
	cursor:pointer;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	border-left:none;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_desPromo__26MED {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	margin-top:20px;
	text-align:center;
	color:#000000;
}

.Style_headerGroup__35Jvh {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	color:#333333;
	border-bottom:1px solid #cccccc;
	margin-top:40px;
}

.Style_redwarn__38C6p {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:20px;
	color:#ff0000;
	margin-top:10px;
	margin-bottom:20px;
}

.Style_shopPage__2cHju {
	width:100vw;
	max-width:100%;
	min-height:-webkit-calc(100vh - 189px);
	min-height:-moz-calc(100vh - 189px);
	min-height:calc(100vh - 189px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_topBlank__14yHd {
	height:80px;
}

.Style_bigheader__2pMoy {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:40px;
	text-align:center;
}

.Style_smallheader__1AdWh {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	opacity:0.3;
	text-align:center;
}

.Style_payHeader__2fA2X {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	color:#333333;
	text-decoration:underline;
	margin-top:18px;
}

.Style_fail__2s78y {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ff0000;
	margin-top:10px;
}

.Style_cartHeader__19M6U {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	color:#333333;
	text-decoration:underline;
	margin-top:40px;
}

.Style_payLabel__3ENf6 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#333333;
	margin-top:12px;
	margin-left:11px;
}

.Style_payInfo__2UB14 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#333333;
	margin-top:18px;
	margin-bottom:10px;
}

.Style_payInfo2__-BOXm {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:10px;
	margin-left:11px;
}

.Style_payChildBold__1nMcL {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	color:#333333;
	margin-left:20px;
}

.Style_sheetCover__3waEW {
	width:100px;
	height:100px;
}

.Style_sheetContain__2kPe6 {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-top:45px;
}

.Style_sheetLeft__1LetH {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	   -moz-box-pack: start;
	        justify-content: flex-start;
}

.Style_sheetRight__1cnFi {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	        flex-direction: column;
	-webkit-justify-content: flex-end;
	   -moz-box-pack: end;
	        justify-content: flex-end;
	-webkit-align-items: flex-end;
	   -moz-box-align: end;
	        align-items: flex-end;
	min-width:100px;
}

.Style_sheetLeftInfo__281GF {
	margin-left:20px;
}

.Style_sheetName__3_TaZ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:left;
	color:#333333;
	line-height:20px;
	word-break: break-all;
}

.Style_sheetAuth__2xJFF {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	text-align:left;
	color:#666666;
	margin-top:10px;
	word-break: break-all;
}

.Style_sheetPrice__3JGQm {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	text-align:right;
	color:#333333;
}

.Style_sheetDelete__KfhVr {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#E15050;
	cursor:pointer;
	text-align:right;
	line-height:16px;
}

.Style_noCart__oF5zf {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:18px;
	margin-top:40px;
	text-align:center;
	color:#000000;
}

.Style_noCartButt__1nV-- {
	height:30px;
	width:200px;
	border:2px solid black;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ffffff;
	background-color:#3399ff;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-top:20px;
}

.Style_promoCode__3tcCb {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:15px;
	margin-top:20px;
	text-align:center;
	color:green;
	margin-top:50px;
}

.Style_totalPrice__2BpA4 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	margin-top:20px;
	text-align:center;
	color:#000000;
	margin-top:50px;
}

.Style_goButt__1lRGR {
	height:45px;
	width:270px;
	background: -webkit-linear-gradient(345.8deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(345.8deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(345.8deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(104.2deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	margin-top:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	line-height:45px;
	text-align:center;
	cursor:pointer;
}

.Style_fourdig__3spLR {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	height:34px;
	width:68px;
	color:#333333;
	border: 1px solid #BDBDBD;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:20px;
	margin-top:10px;
}

.Style_selectContainer__2mu9l {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	margin-left:20px;
	margin-top:10px;
	background: #FAFAFA;
	border-top: 1px solid #BDBDBD;
	border-bottom: 1px solid #BDBDBD;
	border-right: 1px solid #BDBDBD;
	height:34px;
	width:300px;
}

.Style_selectContainer2__2w-uG {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	margin-left:20px;
	margin-top:10px;
	background: #FAFAFA;
	border-top: 1px solid #BDBDBD;
	border-bottom: 1px solid #BDBDBD;
	border-right: 1px solid #BDBDBD;
	height:34px;
	width:200px;
}

.Style_selectTop__vi03y {
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #BDBDBD;
	height:34px;
	width:100px;
	color:#333333;
	cursor:pointer;
}

.Style_selectTop__vi03y:hover {
	background-color:#F5F5F5;
}

.Style_spinCov__2dCYk {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	margin-top:20px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
}

.Style_loader__8xg3L {
	border: 5px solid #eaeaea;
	border-top: 5px solid #00437a;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__bXYqW 2s linear infinite;
	   -moz-animation: Style_spin__bXYqW 2s linear infinite;
	     -o-animation: Style_spin__bXYqW 2s linear infinite;
	        animation: Style_spin__bXYqW 2s linear infinite;
	margin-right:10px;
}

@-webkit-keyframes Style_spin__bXYqW {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes Style_spin__bXYqW {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes Style_spin__bXYqW {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Style_spin__bXYqW {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}


.Style_QRTop2__3HOt0 {
	width:250px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-top:50px;
}

.Style_QRBottom__32GuK {
	width:250px;
	height:50px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-top:30px;
	background-color:#5e3b95;
}

.Style_qrText__1OCHH {
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
}

.Style_blankQR__qkaCd {
	height:10px;
}

.Style_groupFix__kO8BI {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	margin-top:20px;
}

.Style_spaceFix__3vs0d {
	height:40px;
}
.Style_warFix__1mKu8 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	text-align:center;
	color:#FF0000;
}

.Style_linkFix__uDwgQ {
	color:#3399ff;
}

.Style_textFix__QkohZ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:15px;
	width:200px;
	height:30px;
	color:#333333;
	border:1px solid black;
	margin-left:5px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding:5px;
}

.Style_submitFix__1sb6M {
	height:30px;
	line-height:30px;
	width:100px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	color:#333333;
	border:1px solid black;
	text-align:center;
	cursor:pointer;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	border-left:none;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_walletAnnounce__1tvSr {
	width:300px;
	height:300px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.Style_warning__1XXKe {
	color:#ff0000;
}
.Style_shopPage__7vrOg {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_sheetname__3QHVO {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:30px;
	line-height:40px;
}

.Style_blankTop__1y3Wz {
	height:80px;
}

.Style_nothing__2BsVG {
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_headerExam__PUAmS {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:22px;
	text-align:center;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_headerExamNoBot__1kFGq {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:22px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_headerExamSmall__Od4Yi {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:13px;
	text-align:center;
	display:block;
	margin-top:2px;
	margin-bottom:20px;
}

.Style_sheetInfoWrapper__39vLK {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	        justify-content:flex-end;
}

.Style_sheetCover__Z-fmi {
	margin-top:8px;
	width:300px;
	height:300px;
	display:block;
	-webkit-box-shadow: 0px 20.6875px 41.375px rgba(0, 0, 0, 0.08), 0px 8.275px 8.275px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 20.6875px 41.375px rgba(0, 0, 0, 0.08), 0px 8.275px 8.275px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 20.6875px 41.375px rgba(0, 0, 0, 0.08), 0px 8.275px 8.275px rgba(0, 0, 0, 0.02);
	-webkit-border-radius: 12.4125px;
	   -moz-border-radius: 12.4125px;
	        border-radius: 12.4125px;
}

.Style_sheetCover__Z-fmi.Style_mobile__2Z0dA {
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;
}

.Style_sheetContain__2kAAD {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:10px;
	padding-right:10px;
	margin-top:10px;
	margin-bottom:10px;
}

.Style_sheetLeft__qrnjK {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_sheetRight__2axq8 {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	margin-left:10%;
	width:45%;
}

.Style_infoHead__333OJ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	margin-top:15px;
}

.Style_infoData__kusQ7 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding-left:40px;
}

.Style_infoLink__-JBYd {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#666666;
	margin-top:8px;
}

.Style_infoSemester__1q2zB {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#000000;
	margin-top:0px;
}

.Style_openSheet__1fS0j {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:168px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.Style_leftbutt__2Als7 ,.Style_rightbutt__zgkdJ {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	height:300px;
	width:20px;
	line-height:300px;
	border:1px solid black;
	text-align:center;
	cursor:pointer;
	background-color:#000000;
	color:#ffffff;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_leftbutt__2Als7:hover ,.Style_rightbutt__zgkdJ:hover {
	background-color:#555555;
}

.Style_leftbuttBig__2slh9 ,.Style_rightbuttBig__C_ScH {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	height:100vh;
	width:50px;
	line-height:100vh;
	text-align:center;
	cursor:pointer;
	background-color:#000000;
	color:#ffffff;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_leftbuttBig__2slh9:hover ,.Style_rightbuttBig__C_ScH:hover {
	background-color:#555555;
}

.Style_closeBig__1LqVO {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	height:50px;
	width:-webkit-calc(100vw - 120px);
	width:-moz-calc(100vw - 120px);
	width:calc(100vw - 120px);
	line-height:50px;
	text-align:center;
	cursor:pointer;
	background-color:#000000;
	color:#ffffff;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	position:absolute;
	left:50px;
	top:0;
}

.Style_closeBig__1LqVO:hover {
	background-color:#555555;
}

.Style_examDisp__Vmose {
	width:300px;
	height:300px;
	border-top:5px solid black;
	border-bottom:5px solid black;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	position:relative;
	cursor:pointer;
}

.Style_examDispBig__3wcVw {
	width:-webkit-calc(100vw - 100px);
	width:-moz-calc(100vw - 100px);
	width:calc(100vw - 100px);
	height:-webkit-calc(100vh - 50px);
	height:-moz-calc(100vh - 50px);
	height:calc(100vh - 50px);
	border-top:5px solid black;
	border-bottom:5px solid black;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	margin-top:50px;
	position:relative;
}

.Style_imgDisp__3egOE {
	width:300px;
	height:290px;
	position:absolute;
	left:0px;
	-webkit-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}
.Style_imgDispBig__3ym1X {
	width:100%;
	height:-webkit-calc(100vh - 50px);
	height:-moz-calc(100vh - 50px);
	height:calc(100vh - 50px);
	position:absolute;
	left:0px;
	-webkit-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}
.Style_imgGone__QutKp {
	opacity:0;
}

.Style_examContainer__hhkfH {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	margin-bottom:35px;
}

.Style_examContainerBig__3XQGS {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	position:fixed;
	left:0;
	top:0;
	width:100vw;
	max-width:100%;
	background-color:#000000;
	z-index:1000000;
}

.Style_cusHR__1u93x {
	width:100%;
	border:1px solid #E2E2E2;
	margin-top:15px;
}

.Style_cartPanel__34Wg3 {
	margin-top:20px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
}

.Style_addCart__3GgnB {
	width: 178px;
	height: 51px;
	background: -webkit-linear-gradient(353.4deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.4deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.4deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.6deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	font-family: 'Mitr', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
	cursor:pointer;
	line-height:51px;
	text-align:center;
	margin-left:30px;
}

.Style_infoDataPrice__1GM5D {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:32px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#333333;
	line-height:32px;
}

.Style_infoDataPriceCur__314Dn {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	line-height:18px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#BDBDBD;
	text-align:center;
	margin-top:5px;
}

.Style_bought__2Jzik {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#333333;
	line-height:20px;
	margin-top:15px;
	position:relative;
}

.Style_boughtLight__1_8eK {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#333333;
	opacity:0.4;
	line-height:14px;
	margin-top:15px;
}

.Style_sheetWrapper__V-3VK {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_facebookShare__3JzLi {
	width:300px;
	height:30px;
	border:1px solid black;
	-webkit-border-radius:4px;
	   -moz-border-radius:4px;
	        border-radius:4px;
	cursor:pointer;
	margin-top:20px;
	margin-bottom:20px;
	margin-left:auto;
	margin-right:auto;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	text-align:center;
	line-height:30px;
	background-color:#4267b2;
	color:#ffffff;
}

.Style_favorite__1Ofxg {
	width:300px;
	height:30px;
	border:2px solid #cc3333;
	-webkit-border-radius:4px;
	   -moz-border-radius:4px;
	        border-radius:4px;
	cursor:pointer;
	margin-top:20px;
	margin-bottom:20px;
	margin-left:auto;
	margin-right:auto;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	text-align:center;
	line-height:30px;
	background-color:#ffffff;
	color:#cc3333;
}

.Style_boxG__2qAdj {
	height:23px;
	border:1px solid #dadada;
	width:285px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	margin-bottom:5px;
	margin-top:5px;
	margin-left:auto;
	margin-right:auto;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	cursor:pointer;
}

.Style_star__22voP {
	height:15px;
}
.Style_starRev__oG07F {
	height:20px;
}
.Style_starRevBig__2zmk5 {
	height:20px;
	position:relative;
	top:2px;
}

.Style_box0__3Unwq {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:50px;
	border-right:1px solid #dadada;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_box1__2iS42 {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:23px;
	border-right:1px solid #dadada;
}
.Style_box2__1cpTa {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:42px;
	border-right:1px solid #dadada;
}
.Style_box3__3dhcP {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:58px;
	border-right:1px solid #dadada;
}
.Style_box4__2fgf0 {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:74px;
	border-right:1px solid #dadada;
}
.Style_box5__1JLMG {
	height:100%;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	width:86px;
}

.Style_sBox__1cR-a {
	background: #000000;
	color:#ffffff;
}

.Style_starcon__1L6wV {
	margin-top:15px;
}

.Style_rmessage__2aQan {
	padding-left:20px;
	margin-top:5px;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
}

.Style_rDate__17iFX {
	margin-top:5px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
}

.Style_commentContainer__1kR5i {
	width:100%;
	max-width:500px;
	margin-left:auto;
	margin-right:auto;
	display:block;
}

.Style_commentControl__1xWoc {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
	font-family: 'Mitr', sans-serif;
	font-size:18px;
	margin-bottom: 20px;
}

.Style_ccc__3e_Qf {
	margin-left:10px;
	margin-right:10px;
	color:#ffffff;
	cursor:pointer;
	border:1px solid black;
	width:40px;
	height:30px;
	line-height:30px;
	background-color: #cc3333;
	text-align: center;
}

.Style_favoriteBox__35R2u {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:320px;
	position:fixed;
	top:-webkit-calc(50vh - 160px);
	top:-moz-calc(50vh - 160px);
	top:calc(50vh - 160px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_addFavoriteBox__On6F8 {
	border:1px solid black;
	z-index:100000;
	width:300px;
	height:175px;
	position:fixed;
	top:-webkit-calc(50vh - 88px);
	top:-moz-calc(50vh - 88px);
	top:calc(50vh - 88px);
	left:-webkit-calc(50vw - 150px);
	left:-moz-calc(50vw - 150px);
	left:calc(50vw - 150px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_addFavText__3LmqO {
	width:100%;
	height:30px;
	border:1px solid #cccccc;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:10px;
	padding-right:10px;
}

.Style_fClose__tHVDB {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_fArea__2ZuLC {
	height:125px;
	overflow-y:auto;
	overflow-x:hidden;
}

.Style_fHeader__15URE {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_fLabel__2LaoL {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fNothing__Vp84- {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#aaaaaa;
	text-align:center;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fAdd__2c-Ea {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_fAdd__2c-Ea.Style_inactive__Vr796 {
	opacity:0.3;
	cursor:not-allowed;
}

.Style_aAdd__3LMnD {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_bGroup__1MfeD {
	position:absolute;
	bottom:10px;
	left:10px;
	width:280px;
}

.Style_listBox__3v9h6 {
	height:30px;
	width:100%;
	border:1px solid #cccccc;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	margin-top:5px;
	margin-bottom:5px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	background-color:#ffffff;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12px;
	cursor:pointer;
}
.Style_listBox__3v9h6.Style_active__2kNHm {
	border:1px solid #000000;
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
}

.Style_listCheck__33336 {
	width:15px;
	height:15px;
	border:1px solid #cccccc;
	-webkit-border-radius:15px;
	   -moz-border-radius:15px;
	        border-radius:15px;
	margin-left:10px;
	margin-right:10px;
	background-color:#ffffff;
}
.Style_listCheck__33336.Style_active__2kNHm {
	border:1px solid #000000;
	background-color: #000000;
}
.Style_listName__2hS7c {
	overflow:hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	line-height:30px;
	height:30px;
	width:-webkit-calc(100% - 25px);
	width:-moz-calc(100% - 25px);
	width:calc(100% - 25px);
}
.Style_testad__2XVcp {
	width:100%;
}
.Style_picbutt__1u6RM {
	margin-top:20px;
	padding:0;
	border:0;
	width:100%;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	cursor:pointer;
	outline:none;
	background-color:transparent;
}

.Style_nocomment__3GSEc {
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#cc3333;
}

.Style_banner350__dJeiq {
	width:350px;
}

.Style_banner700__1ekE5 {
	width:100%;
}

.Style_containn__3yViW {
	margin-left:auto;
	margin-right:auto;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	width:100%;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
}
.Style_shopPage__BQGCB {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_sheetname__2uWcL {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:30px;
	line-height:40px;
}

.Style_blankTop__3_B5M {
	height:80px;
}

.Style_nothing__Aw-IY {
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_headerExam__yDSs8 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:22px;
	text-align:center;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_sheetInfoWrapper__1Cq31 {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	-webkit-justify-content:flex-end;
	   -moz-box-pack:end;
	        justify-content:flex-end;
}

.Style_sheetCover__35BA_ {
	margin-top:8px;
	width:300px;
	height:300px;
	display:block;
	-webkit-box-shadow: 0px 20.6875px 41.375px rgba(0, 0, 0, 0.08), 0px 8.275px 8.275px rgba(0, 0, 0, 0.02);
	   -moz-box-shadow: 0px 20.6875px 41.375px rgba(0, 0, 0, 0.08), 0px 8.275px 8.275px rgba(0, 0, 0, 0.02);
	        box-shadow: 0px 20.6875px 41.375px rgba(0, 0, 0, 0.08), 0px 8.275px 8.275px rgba(0, 0, 0, 0.02);
	-webkit-border-radius: 12.4125px;
	   -moz-border-radius: 12.4125px;
	        border-radius: 12.4125px;
	margin-bottom:20px;
}

.Style_sheetCover__35BA_.Style_mobile__2OaSF {
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;
}

.Style_sheetContain__1hAno {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	   -moz-box-pack: justify;
	        justify-content: space-between;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:10px;
	padding-right:10px;
	margin-top:10px;
	margin-bottom:10px;
}

.Style_sheetLeft__3bAe0 {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_sheetRight__2qpMn {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	margin-left:10%;
	width:45%;
}

.Style_infoHead__3QyLY {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	margin-top:15px;
}

.Style_infoData__2GL8X {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding-left:40px;
}

.Style_infoLink__3C08v {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:20px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#666666;
	margin-top:8px;
}

.Style_infoSemester__EO1sU {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#000000;
	margin-top:0px;
}

.Style_openSheet__r-lBw {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#333333;
	height:32px;
	width:168px;
	line-height:32px;
	text-align:center;
	cursor:pointer;
	margin-top:10px;
	background: -webkit-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.84deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.16deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 2px 6px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.Style_imgDisp__3px1n {
	width:300px;
	height:290px;
	position:absolute;
	left:0px;
	-webkit-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}
.Style_imgDispBig__1dzvV {
	width:100%;
	height:-webkit-calc(100vh - 50px);
	height:-moz-calc(100vh - 50px);
	height:calc(100vh - 50px);
	position:absolute;
	left:0px;
	-webkit-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}
.Style_imgGone__EaFT- {
	opacity:0;
}

.Style_examContainer__3BKtv {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	margin-bottom:35px;
}

.Style_examContainerBig__3BAI- {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
	position:fixed;
	left:0;
	top:0;
	width:100vw;
	max-width:100%;
	background-color:#000000;
	z-index:1000000;
}

.Style_cusHR__30JyU {
	width:100%;
	border:1px solid #E2E2E2;
	margin-top:15px;
}

.Style_cartPanel__bPTRR {
	margin-top:20px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:flex-start;
	   -moz-box-pack:start;
	        justify-content:flex-start;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
}

.Style_addCart__1RY84 {
	width: 230px;
	height: 51px;
	background: -webkit-linear-gradient(353.4deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(353.4deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(353.4deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(96.6deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	   -moz-box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	        box-shadow: 0px 5px 16px rgba(255, 205, 9, 0.45);
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	        border-radius: 2px;
	font-family: 'Mitr', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
	cursor:pointer;
	line-height:51px;
	text-align:center;
	margin-left:30px;
}

.Style_infoDataPrice__2UFVL {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:32px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#333333;
	line-height:32px;
}

.Style_infoDataPriceCur__q9vou {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:18px;
	line-height:18px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#BDBDBD;
	text-align:center;
	margin-top:5px;
}

.Style_bought__21OpV {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#333333;
	line-height:20px;
	margin-top:15px;
	position:relative;
}

.Style_boughtLight__Am8Wg {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	color:#333333;
	opacity:0.4;
	line-height:14px;
	margin-top:15px;
}

.Style_sheetWrapper__2cK7Y {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_facebookShare__1f9QW {
	width:300px;
	height:30px;
	border:1px solid black;
	-webkit-border-radius:4px;
	   -moz-border-radius:4px;
	        border-radius:4px;
	cursor:pointer;
	margin-top:20px;
	margin-bottom:20px;
	margin-left:auto;
	margin-right:auto;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	text-align:center;
	line-height:30px;
	background-color:#4267b2;
	color:#ffffff;
}
.Style_sheetPage__3jhNR {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#EBEBEB;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-top:50px;
}

.Style_container__3qLIV {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__2y0UX {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_explain__3l4c6 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_label__2vnjv {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:18px;
	display:block;
	margin-top:10px;
}

.Style_queueLabel__2cktS {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	margin-top:5px;
	color:#666666;
	text-align:center;
}

.Style_explainLabel__3UHIk {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:#666666;
}

.Style_textField__37MJ0 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:40px;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_textFieldHalf__2x5K_ {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:inline-block;
	width:50%;
	height:40px;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_textArea__26wIA {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:100px;
	padding-left:10px;
	padding-top:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:50px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fileField__3dlNO {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-top:7px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_notFound__x2Yja {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__jO7Jb {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__22MHT {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__1Nkv2 {
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
	height:30px;
	border:2px solid orange;
	background-color:#ffc700;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	line-height:30px;
	text-align:center;
}

.Style_Link__Pbc8n {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
	cursor:pointer;
}

.Style_data__1bS7A {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#666666;
	margin-top:10px;
}

.Style_line__1SeFe {
	display:block;
	width:100%;
	height:16px;
}

.Style_requestButton__3MuSr {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:#3399ff;
	cursor:pointer;
}

.Style_sendButton__1zGtP {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:green;
	cursor:pointer;
}

.Style_rejectButton__3e2T5 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:red;
	cursor:pointer;
}

.Style_smallLoader__13QzS {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__1xch- 2s linear infinite;
	   -moz-animation: Style_spin__1xch- 2s linear infinite;
	     -o-animation: Style_spin__1xch- 2s linear infinite;
	        animation: Style_spin__1xch- 2s linear infinite;
}

@-webkit-keyframes Style_spin__1xch- {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes Style_spin__1xch- {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes Style_spin__1xch- {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Style_spin__1xch- {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_reject__1eFTR {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__1FCwR {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__1BgQ0 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}
[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box-verifySheet {
	margin-top:10px;
    width: 100%;
	height:40px;
    position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
}


    .select-search-box-verifySheet::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #222f3e;
        z-index: 1;
    }

    
    .select-search-box-verifySheet__icon--disabled::after {
        content: none !important;
    }

    .select-search-box-verifySheet--input::after {
        display: none !important;
    }

    .select-search-box-verifySheet__out {
        display: none;
    }

    .select-search-box-verifySheet__search {
        display: block;
        width: 100%;
        height: 40px;
        border: none;
        background: none;
        outline: none;
        color: #222f3e;
        -webkit-appearance: none;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        position: relative;
        z-index: 2;
        cursor: pointer;
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        line-height: 40px;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    input.select-search-box-verifySheet__search {
        line-height: 1;
    }

    .select-search-box-verifySheet__search {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    .select-search-box-verifySheet--input .select-search-box-verifySheet__search {
        cursor: text;
    }

        .select-search-box-verifySheet__search:focus {
            cursor: text;
        }

        .select-search-box-verifySheet__search--placeholder {
            font-weight: 500;
        }

    .select-search-box-verifySheet input::-webkit-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input::-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-ms-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:30px;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box-verifySheet__select {
        display: none;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        background: #fff;
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        overflow: auto;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
		border:1px solid #AAAAAA;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
        z-index: 100;
        min-height: 49px;
    }

        .select-search-box-verifySheet__select--display {
            display: block;
        }

    .select-search-box-verifySheet__option {
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        color: #616b74;
        padding: 15px 20px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .select-search-box-verifySheet__option:first-child {
            border-top: none;
        }

        .select-search-box-verifySheet__option--hover, .select-search-box-verifySheet__option:hover {
            background: #f4f7fa;
        }

        .select-search-box-verifySheet__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box-verifySheet__option--selected:hover, .select-search-box-verifySheet__option--selected.select-search-box-verifySheet__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box-verifySheet__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box-verifySheet__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
               -moz-transform: translate(-50%, -50%);
                 -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }
.Style_sheetPage__3nl-m {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#EBEBEB;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-top:50px;
}

.Style_container__2LPda {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__1wb3O {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_explain__343M9 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_label__1ppn_ {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:18px;
	display:block;
	margin-top:10px;
}

.Style_queueLabel__3AoB_ {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	margin-top:5px;
	color:#666666;
	text-align:center;
}

.Style_explainLabel__dDEmY {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:#666666;
}

.Style_textField__3FJ-W {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:40px;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_textFieldHalf__1VIec {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:inline-block;
	width:50%;
	height:40px;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_textArea__1K3PE {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:100px;
	padding-left:10px;
	padding-top:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:50px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fileField__2sC1F {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-top:7px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_notFound__2pd4v {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__2jWV5 {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__11EO0 {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__un3-S {
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
	height:30px;
	border:2px solid orange;
	background-color:#ffc700;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	line-height:30px;
	text-align:center;
}

.Style_Link__2-9n4 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
	cursor:pointer;
}

.Style_data__1drXY {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#666666;
	margin-top:10px;
}

.Style_line__3P6Gi {
	display:block;
	width:100%;
	height:16px;
}

.Style_requestButton__1wAqD {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:#3399ff;
	cursor:pointer;
}

.Style_monthyear__11Pyo {
	width:100%;
	height:30px;
	margin-top:20px;
}

.Style_buttonPayment__3m-Ld {
	width:100%;
	height:30px;
	margin-top:20px;
	border:1px solid black;
	cursor:pointer;
}

.Style_smallLoader__3tweY {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__2lC4u 2s linear infinite;
	   -moz-animation: Style_spin__2lC4u 2s linear infinite;
	     -o-animation: Style_spin__2lC4u 2s linear infinite;
	        animation: Style_spin__2lC4u 2s linear infinite;
}

.Style_table__QV0b9 {
	width:100%;
	border:1px solid black;
	text-align:center;
	background-color:#FFFFFF;
	border-collapse: collapse;
}

.Style_row__2l4H_ {
	border:1px solid black;
}

@-webkit-keyframes Style_spin__2lC4u {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes Style_spin__2lC4u {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes Style_spin__2lC4u {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Style_spin__2lC4u {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}
[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box-verifySheet {
	margin-top:10px;
    width: 100%;
	height:40px;
    position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
}


    .select-search-box-verifySheet::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #222f3e;
        z-index: 1;
    }

    
    .select-search-box-verifySheet__icon--disabled::after {
        content: none !important;
    }

    .select-search-box-verifySheet--input::after {
        display: none !important;
    }

    .select-search-box-verifySheet__out {
        display: none;
    }

    .select-search-box-verifySheet__search {
        display: block;
        width: 100%;
        height: 40px;
        border: none;
        background: none;
        outline: none;
        color: #222f3e;
        -webkit-appearance: none;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        position: relative;
        z-index: 2;
        cursor: pointer;
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        line-height: 40px;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    input.select-search-box-verifySheet__search {
        line-height: 1;
    }

    .select-search-box-verifySheet__search {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    .select-search-box-verifySheet--input .select-search-box-verifySheet__search {
        cursor: text;
    }

        .select-search-box-verifySheet__search:focus {
            cursor: text;
        }

        .select-search-box-verifySheet__search--placeholder {
            font-weight: 500;
        }

    .select-search-box-verifySheet input::-webkit-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input::-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-ms-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:30px;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box-verifySheet__select {
        display: none;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        background: #fff;
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        overflow: auto;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
		border:1px solid #AAAAAA;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
        z-index: 100;
        min-height: 49px;
    }

        .select-search-box-verifySheet__select--display {
            display: block;
        }

    .select-search-box-verifySheet__option {
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        color: #616b74;
        padding: 15px 20px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .select-search-box-verifySheet__option:first-child {
            border-top: none;
        }

        .select-search-box-verifySheet__option--hover, .select-search-box-verifySheet__option:hover {
            background: #f4f7fa;
        }

        .select-search-box-verifySheet__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box-verifySheet__option--selected:hover, .select-search-box-verifySheet__option--selected.select-search-box-verifySheet__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box-verifySheet__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box-verifySheet__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
               -moz-transform: translate(-50%, -50%);
                 -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }
.Style_sheetPage__1xRUg {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#EBEBEB;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-top:50px;
}

.Style_container__DV3jF {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__8P-_Y {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_explain__3YnWy {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_label__3ZnHH {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:18px;
	display:block;
	margin-top:10px;
}

.Style_queueLabel__3QH53 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	margin-top:5px;
	color:#666666;
	text-align:center;
}

.Style_explainLabel__3QNzl {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:#666666;
}

.Style_dataPull__mSLK4 {
	height:40px;
	margin-top:30px;
	background-color:white;
	cursor:pointer;
	border:1px solid black;
	line-height:40px;
	text-align:center;
}

.Style_loading__1oLHk {
	font-size:25px;
	margin-top:20px;
	text-align:center;
}

.Style_firstLine__1Q7SE {
	cursor:pointer;
	color:#3399ff;
}

.Style_shead__34o-4 {
	font-weight:bold;
	margin-top:10px;
}

.Style_sarea__2nvqN {
	width:400px;
	height:100px;
}

.Style_sbutt__1QUz_ {
	margin-top:10px;
	height:30px;
	width:400px;
	color:#ffffff;
	font-weight:bold;
	line-height:30px;
	border:1px solid black;
	background-color:green;
	text-align:center;
	cursor:pointer;
}
.Style_sheetPage__1H9SL {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#EBEBEB;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-top:50px;
}

.Style_container__s8zvY {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__2pcoH {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_explain__Q_cS3 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_label__kQyiF {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:18px;
	display:block;
	margin-top:10px;
}

.Style_queueLabel__WpO4p {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	margin-top:5px;
	color:#666666;
	text-align:center;
}

.Style_explainLabel__3Vx1N {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:#666666;
}

.Style_dataPull__2Ffof {
	height:40px;
	margin-top:30px;
	background-color:white;
	cursor:pointer;
	border:1px solid black;
	line-height:40px;
	text-align:center;
}

.Style_loading__6om5e {
	font-size:25px;
	margin-top:20px;
	text-align:center;
}

.Style_sheetPage__Ii35C {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_container__3nq0E {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__1Oao7 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_subheader__1tNBi {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	text-align:center;
	display:block;
}

.Style_linkBox__3qp20 {
	width:200px;
	height:200px;
	border:2px solid black;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	display:block;
	background-color:#000000;
	line-height:200px;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	color:#ffffff;
}

.Style_linkBox__3qp20:hover {
	color:#3399ff;
	background-color:#ffffff;
}
.Style_sheetPage__3oDDy {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_container__1ycg9 {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__3f_iU {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_subheader__1tlRE {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	text-align:center;
	display:block;
}

.Style_smallheader__UmMCC {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	text-align:center;
	display:block;
	margin-top:10px;
}


.Style_nothing__3rw79 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	text-align:center;
	display:block;
	margin-top:10px;
	color:#ff0000;
}

.Style_sellerRes__2EiQr {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	text-align:center;
	display:block;
	margin-top:10px;
	color:#3399ff;
	cursor:pointer;
}

.Style_linkBox__3wgY2 {
	width:200px;
	height:200px;
	border:2px solid black;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	display:block;
	background-color:#000000;
	line-height:200px;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	color:#ffffff;
}

.Style_linkBox__3wgY2:hover {
	color:#3399ff;
	background-color:#ffffff;
}

.Style_searchBar__10ub7 {
	width:100%;
	height:34px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	padding-left:10px;
	outline:none;
}

.Style_label__FtNdD {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	color:#000000;
	margin-top:10px;
}

.Style_value__2GZQS {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#3399ff;
	padding-left:10px;
	
}
.Style_sheetPage__3taG2 {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#EBEBEB;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-top:50px;
}

.Style_container__2QWzi {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__16yO9 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_explain__3hsSt {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:20px;
	margin-bottom:20px;
}

.Style_label__2Q4bJ {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:18px;
	display:block;
	margin-top:10px;
}

.Style_queueLabel__1w1On {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	margin-top:5px;
	color:#666666;
	text-align:center;
}

.Style_explainLabel__Wd33g {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:#666666;
}

.Style_textField__2sUF5 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:40px;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_textFieldHalf__3uDVh {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:inline-block;
	width:50%;
	height:40px;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_textArea__31cyT {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:100px;
	padding-left:10px;
	padding-top:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:50px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fileField__3HdeM {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	display:block;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-top:7px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	margin-bottom:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_notFound__2G_B4 {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__3d51g {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__1VpAK {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__mZLXM {
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
	height:30px;
	border:2px solid orange;
	background-color:#ffc700;
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	line-height:30px;
	text-align:center;
}

.Style_Link__1J8Oo {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
	cursor:pointer;
}

.Style_data__2PMXh {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#666666;
	margin-top:10px;
}

.Style_line__1_7vQ {
	display:block;
	width:100%;
	height:16px;
}

.Style_requestButton__2ptnE {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:#3399ff;
	cursor:pointer;
}

.Style_sendButton__Jqp4O {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:green;
	cursor:pointer;
}

.Style_rejectButton__3G-kG {
	font-family: 'Prompt', sans-serif;
	font-weight:500;
	font-size:16px;
	color:white;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	border:2px solid black;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	background-color:red;
	cursor:pointer;
}

.Style_smallLoader__3iaTh {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__ZCZJv 2s linear infinite;
	   -moz-animation: Style_spin__ZCZJv 2s linear infinite;
	     -o-animation: Style_spin__ZCZJv 2s linear infinite;
	        animation: Style_spin__ZCZJv 2s linear infinite;
}

@-webkit-keyframes Style_spin__ZCZJv {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes Style_spin__ZCZJv {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes Style_spin__ZCZJv {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Style_spin__ZCZJv {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_reject__2uSQb {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__2-QTh {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__Zlttx {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}
[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box-verifySheet {
	margin-top:10px;
    width: 100%;
	height:40px;
    position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:10px;
	background-color:white;
	border:1px solid #AAAAAA;
	-webkit-border-radius:25px;
	   -moz-border-radius:25px;
	        border-radius:25px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
}


    .select-search-box-verifySheet::after {
        font-family: fontawesome;
        content:"\f078";
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        text-align: center;
        line-height: 40px;
        color: #222f3e;
        z-index: 1;
    }

    
    .select-search-box-verifySheet__icon--disabled::after {
        content: none !important;
    }

    .select-search-box-verifySheet--input::after {
        display: none !important;
    }

    .select-search-box-verifySheet__out {
        display: none;
    }

    .select-search-box-verifySheet__search {
        display: block;
        width: 100%;
        height: 40px;
        border: none;
        background: none;
        outline: none;
        color: #222f3e;
        -webkit-appearance: none;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        position: relative;
        z-index: 2;
        cursor: pointer;
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        line-height: 40px;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    input.select-search-box-verifySheet__search {
        line-height: 1;
    }

    .select-search-box-verifySheet__search {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
    }

    .select-search-box-verifySheet--input .select-search-box-verifySheet__search {
        cursor: text;
    }

        .select-search-box-verifySheet__search:focus {
            cursor: text;
        }

        .select-search-box-verifySheet__search--placeholder {
            font-weight: 500;
        }

    .select-search-box-verifySheet input::-webkit-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input::-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-moz-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:40px;
    }

    .select-search-box-verifySheet input:-ms-input-placeholder {
        color: #ccc;
        font-weight: 500;
		line-height:30px;
    }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box-verifySheet__select {
        display: none;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        background: #fff;
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        overflow: auto;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
		border:1px solid #AAAAAA;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
        z-index: 100;
        min-height: 49px;
    }

        .select-search-box-verifySheet__select--display {
            display: block;
        }

    .select-search-box-verifySheet__option {
        font-family: 'Prompt', sans-serif;
		font-weight:500;
		font-size:16px;
        color: #616b74;
        padding: 15px 20px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .select-search-box-verifySheet__option:first-child {
            border-top: none;
        }

        .select-search-box-verifySheet__option--hover, .select-search-box-verifySheet__option:hover {
            background: #f4f7fa;
        }

        .select-search-box-verifySheet__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box-verifySheet__option--selected:hover, .select-search-box-verifySheet__option--selected.select-search-box-verifySheet__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box-verifySheet__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box-verifySheet__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
               -moz-transform: translate(-50%, -50%);
                 -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }
.Style_sheetPage__3LUUW {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_container__2d_nb {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding-left:20px;
	padding-right:20px;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__2YcKC {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:30px;
	text-align:center;
	display:block;
	margin-top:20px;
}

.Style_subheader__2A6gb {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	text-align:center;
	display:block;
}

.Style_smallheader__2Tc6o {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	text-align:center;
	display:block;
	margin-top:10px;
}


.Style_nothing__2Rady {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	text-align:center;
	display:block;
	margin-top:10px;
	color:#ff0000;
}

.Style_sellerRes__2Gg2c {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	text-align:center;
	display:block;
	margin-top:10px;
	color:#3399ff;
	cursor:pointer;
}

.Style_linkBox__1AJyy {
	width:200px;
	height:200px;
	border:2px solid black;
	-webkit-border-radius:5px;
	   -moz-border-radius:5px;
	        border-radius:5px;
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	display:block;
	background-color:#000000;
	line-height:200px;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	color:#ffffff;
}

.Style_linkBox__1AJyy:hover {
	color:#3399ff;
	background-color:#ffffff;
}

.Style_searchBar__2xtvR {
	width:100%;
	height:34px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	padding-left:10px;
	outline:none;
}

.Style_label__FAVUK {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:18px;
	color:#000000;
	margin-top:10px;
}

.Style_value__2OH2n {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#3399ff;
	padding-left:10px;
	
}

.Style_goButt__2eyih {
	font-family: Mitr,sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    height: 32px;
    width: 100%;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    background: -webkit-linear-gradient(353.84deg,#ffe992,#ffcd09);
    background: -moz-linear-gradient(353.84deg,#ffe992,#ffcd09);
    background: -o-linear-gradient(353.84deg,#ffe992,#ffcd09);
    background: linear-gradient(96.16deg,#ffe992,#ffcd09);
    -webkit-box-shadow: 0 2px 6px rgba(255,205,9,.45);
       -moz-box-shadow: 0 2px 6px rgba(255,205,9,.45);
            box-shadow: 0 2px 6px rgba(255,205,9,.45);
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
}
.Style_sheetPage__Pv56r {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__1t6Zq {
	height:40px;
}

.Style_container__16DaA {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__3zkh0 {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__1bIi5 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_label__1urnf {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}


.Style_explainLabel__3PJvV {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_textField__3KV2G {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__1ufOX {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__v6Iaa {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__2JMQw {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__3JACF {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__16nj5 {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__2udOJ {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__LGDFh {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__3a2pO {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__3xwyT {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__3xwyT:hover {
	opacity:0.7;
}

.Style_warnButton__3qPLt {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:70px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #ffcd09;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__3xwyT:hover {
	opacity:0.7;
}

.Style_sendButton__3Nz1_ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__3Nz1_:hover {
	opacity:0.7;
}

.Style_buttContainer__1fhyZ {
	
}

.Style_smallLoader__17ZKM {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__regev 2s linear infinite;
	   -moz-animation: Style_spin__regev 2s linear infinite;
	     -o-animation: Style_spin__regev 2s linear infinite;
	        animation: Style_spin__regev 2s linear infinite;
}

.Style_reject__3PW_P {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__1RQLT {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__3bEVC {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__YM2vR {
	display:block;
	width:100%;
	height:19px;
}

.Style_statusGreen__gSnNJ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#006400;
}

.Style_hideButt__2x7ZK {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_statusRed__3Q4PD {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#ff0000;
}

.Style_showButt__38z7I {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #006400;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_addFavoriteBox__2b4Lu {
	border:1px solid black;
	z-index:100000;
	width:350px;
	padding:10px;
	position:fixed;
	top:50px;
	left:-webkit-calc(50vw - 175px);
	left:-moz-calc(50vw - 175px);
	left:calc(50vw - 175px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_addFavText__1OK0z {
	width:100%;
	height:30px;
	border:1px solid #cccccc;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:10px;
	padding-right:10px;
}

.Style_fClose__2fQhm {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_fArea__1BMSQ {
	height:125px;
	overflow-y:auto;
	overflow-x:hidden;
}

.Style_fHeader__1Nrz9 {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_fLabel__2zSYo {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fNothing__1FnjZ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#aaaaaa;
	text-align:center;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fAdd__1ZXA9 {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_fAdd__1ZXA9.Style_inactive__3SFeh {
	opacity:0.3;
	cursor:not-allowed;
}

.Style_fPlus__1Vria {
	background-color: #00aa00;
	border:1px solid black;
	height:23px;
	width:90px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:10px;
	display:block;
	cursor:pointer;
	text-align:center;
	line-height:23px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ffffff;
}

.Style_fLineCon__2-b3u {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	border-bottom:1px solid black;
	margin-bottom:5px;
}

.Style_fLineText__MrInQ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:40px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineLabel__WOdcX {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fLineSelect__3BoMc {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:100px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineX__3-7Ui {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#ff0000;
	margin-left:10px;
	cursor:pointer;
}

.Style_linkLineCon__3nS1- {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:block;
	text-align:center;
}


@-webkit-keyframes Style_spin__regev {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__regev {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__regev {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__regev {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}
.Style_sheetPage__3qXnt {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__4n-jT {
	height:40px;
}

.Style_container__wbWuQ {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__tSkv8 {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__2bdi3 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_label__Yr1ni {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}


.Style_explainLabel__2HTYd {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_textField__3APIO {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__3XIn- {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__s78Hw {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__1Cl8M {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__1AUxu {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__3khjT {
	width:100px;
	height:100px;
	cursor:pointer;
}

.Style_sampleImage__1Bxc8 {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__3G0O_ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__2jPlL {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__W8GYU {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__W8GYU:hover {
	opacity:0.7;
}

.Style_warnButton__pyHRo {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:70px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #ffcd09;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__W8GYU:hover {
	opacity:0.7;
}

.Style_sendButton__2m0Rl {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__2m0Rl:hover {
	opacity:0.7;
}

.Style_buttContainer__2b32k {
	
}

.Style_smallLoader__xX9_u {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__266kW 2s linear infinite;
	   -moz-animation: Style_spin__266kW 2s linear infinite;
	     -o-animation: Style_spin__266kW 2s linear infinite;
	        animation: Style_spin__266kW 2s linear infinite;
}

.Style_reject__3am-c {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__1TfkD {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__1EzaU {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__2Vc7B {
	display:block;
	width:100%;
	height:19px;
}

.Style_statusGreen__K3q4O {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#006400;
}

.Style_hideButt__hpxEM {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_statusRed__2TTTO {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#ff0000;
}

.Style_showButt__HLbrj {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #006400;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_addFavoriteBox__1jytN {
	border:1px solid black;
	z-index:100000;
	width:350px;
	padding:10px;
	position:fixed;
	top:50px;
	left:-webkit-calc(50vw - 175px);
	left:-moz-calc(50vw - 175px);
	left:calc(50vw - 175px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_addFavText__3LnTL {
	width:100%;
	height:30px;
	border:1px solid #cccccc;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:10px;
	padding-right:10px;
}

.Style_fClose__3tmXh {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_fArea__2WH-u {
	height:125px;
	overflow-y:auto;
	overflow-x:hidden;
}

.Style_fHeader__3EjZR {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_fLabel__FfGkc {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fNothing__1-GbD {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#aaaaaa;
	text-align:center;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fAdd__1O7GT {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_fAdd__1O7GT.Style_inactive__3phOa {
	opacity:0.3;
	cursor:not-allowed;
}

.Style_fPlus__2tAzo {
	background-color: #00aa00;
	border:1px solid black;
	height:23px;
	width:90px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:10px;
	display:block;
	cursor:pointer;
	text-align:center;
	line-height:23px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ffffff;
}

.Style_fLineCon__1J5l3 {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	border-bottom:1px solid black;
	margin-bottom:5px;
}

.Style_fLineText__1oEKe {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:40px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineLabel__3q7ry {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fLineSelect__348-- {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:100px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineX__2fyqs {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#ff0000;
	margin-left:10px;
	cursor:pointer;
}

.Style_linkLineCon__1Mawv {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:block;
	text-align:center;
}


@-webkit-keyframes Style_spin__266kW {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__266kW {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__266kW {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__266kW {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}
.Style_sheetPage__3OjMd {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__zCSJw {
	height:40px;
}

.Style_container__1JFb- {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__3FBQu {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__27Htn {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_label__1hJxm {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}

.Style_labelRed__1qxBG {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	color:#ff0000;
}


.Style_explainLabel__1Cs8k {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_textField__1UCMq {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__3VZeV {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__w0sda {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__13Mo0 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__13Cud {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__2rtlI {
	width:200px;
	height:200px;
	-webkit-border-radius: 200px;
	   -moz-border-radius: 200px;
	        border-radius: 200px;
	cursor:pointer;
}
.Style_coverImage2__uiCX4 {
	width:200px;
	height:200px;
	cursor:pointer;
}

.Style_sampleImage__1vIP8 {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__3OorP {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__zwjoa {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__VRpYS {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__VRpYS:hover {
	opacity:0.7;
}

.Style_warnButton__1PgYo {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:70px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #ffcd09;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__VRpYS:hover {
	opacity:0.7;
}

.Style_sendButton__1pnJD {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__1pnJD:hover {
	opacity:0.7;
}

.Style_buttContainer__2kPWd {
	
}

.Style_smallLoader__RptX0 {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__AlZXv 2s linear infinite;
	   -moz-animation: Style_spin__AlZXv 2s linear infinite;
	     -o-animation: Style_spin__AlZXv 2s linear infinite;
	        animation: Style_spin__AlZXv 2s linear infinite;
}

.Style_reject__2HOhV {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__3T9_G {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__3BhcG {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__2h8Qg {
	display:block;
	width:100%;
	height:19px;
}

.Style_statusGreen__2PL0z {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#006400;
}

.Style_hideButt__2Lvox {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_statusRed__O0skX {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#ff0000;
}

.Style_showButt__1Kt5C {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #006400;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_addFavoriteBox__3lxfK {
	border:1px solid black;
	z-index:100000;
	width:350px;
	padding:10px;
	position:fixed;
	top:50px;
	left:-webkit-calc(50vw - 175px);
	left:-moz-calc(50vw - 175px);
	left:calc(50vw - 175px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_addFavText__23VIH {
	width:100%;
	height:30px;
	border:1px solid #cccccc;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:10px;
	padding-right:10px;
}

.Style_fClose__j7LqE {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_fArea__1oEF_ {
	height:125px;
	overflow-y:auto;
	overflow-x:hidden;
}

.Style_fHeader__1Edql {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_fLabel__1pio0 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fNothing__p6iHu {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#aaaaaa;
	text-align:center;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fAdd__Mle2k {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_fAdd__Mle2k.Style_inactive__2T_kt {
	opacity:0.3;
	cursor:not-allowed;
}

.Style_fPlus__1QIFV {
	background-color: #00aa00;
	border:1px solid black;
	height:23px;
	width:90px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:10px;
	display:block;
	cursor:pointer;
	text-align:center;
	line-height:23px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ffffff;
}

.Style_fLineCon__3zO1F {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	border-bottom:1px solid black;
	margin-bottom:5px;
}

.Style_fLineText__1chFw {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:40px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineLabel__1d4UL {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fLineSelect__ByAKu {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:100px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineX__1GoOu {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#ff0000;
	margin-left:10px;
	cursor:pointer;
}

.Style_linkLineCon__3bKxQ {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:block;
	text-align:center;
}


@-webkit-keyframes Style_spin__AlZXv {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__AlZXv {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__AlZXv {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__AlZXv {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.Style_toggleDriveOn__sULJN {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	margin-bottom:10px;
	display: block;
	margin-left:auto;
	margin-right:auto;
	width:200px;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #008000;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_toggleDriveOff__2k_ci {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	margin-bottom:10px;
	display: block;
	margin-left:auto;
	margin-right:auto;
	width:200px;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_dropzone__nq_H4 {
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 1rem;
       -moz-border-radius: 1rem;
            border-radius: 1rem;
  }
  
  .Style_grid-item__Ea_oo {
    padding: 10px;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: column;
	   -moz-box-orient: vertical;
	   -moz-box-direction: normal;
	        flex-direction: column;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
  }
  
  .Style_grid-item-content__3KLiT {
    width: 50px;
    height: 50px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    background: #08e;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
  }

  .Style_grid-item-content-img__GJjx0 {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
  }

.Style_weirdinput__2cHo4 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	height:20px;
	width:50%;

}

.Style_rowContainer__SDwRm {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
}

.Style_posSwap__pbEad {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	margin-left:5px;
	color:white;
	background-color:#000000;
	width:20px;
	text-align: center;
	cursor: pointer;
}

.Style_deleteSwap__3Kxjh {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	margin-left:5px;
	color:white;
	background-color:#ff0000;
	width:20px;
	text-align: center;
	cursor: pointer;
}

.Style_addSwap__1PkRb {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	margin-left:5px;
	color:white;
	background-color:#22881f;
	width:20px;
	text-align: center;
	cursor: pointer;
}

.Style_underName__3AQkr {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:12;
	width:100%;
	text-align: center;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	white-space: nowrap;
  	overflow: hidden;
}
.Style_sheetPage__90Z52 {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_blankTop__1TdeN {
	height:40px;
}

.Style_container__35eyS {
	width:500px;
	max-width:100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	margin-left:auto;
	margin-right:auto;
}

.Style_header__2ggKa {
	font-family: 'Mitr', sans-serif;
	font-weight:600;
	font-size:40px;
	text-align:center;
}

.Style_explain__1udGp {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-top:8px;
	margin-bottom:5px;
	color:#666666;
}

.Style_label__1Xk4N {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
}

.Style_labelRed__13hRE {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	color:#ff0000;
}


.Style_explainLabel__25PmX {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	color:#666666;
	margin-top:5px;
}

.Style_textField__221vL {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textFieldHalf__3sgQI {
	display:inline-block;
	width:50%;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_textArea__2jwfk {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:150px;
	padding-left:10px;
	padding-right:10px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fileField__oqxuq {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	width:100%;
	height:40px;
	padding-left:10px;
	padding-right:10px;
	padding-top:6px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	margin-top:10px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_notFound__2KrcE {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:12px;
	display:block;
	margin-top:5px;
	color:red;
}

.Style_coverImage__W323S {
	width:200px;
	height:200px;
	-webkit-border-radius: 200px;
	   -moz-border-radius: 200px;
	        border-radius: 200px;
	cursor:pointer;
}
.Style_coverImage2__1BpTT {
	width:200px;
	height:200px;
	cursor:pointer;
}

.Style_sampleImage__3rtb6 {
	width:100px;
	max-height:500px;
	cursor:pointer;
	margin-right:20px;
}

.Style_uploadInfo__L5wEN {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
}

.Style_pdfLink__15ddZ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#3399ff;
	margin-top:20px;
}

.Style_saveButton__2447U {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__2447U:hover {
	opacity:0.7;
}

.Style_warnButton__1whZD {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#333333;
	margin-top:10px;
	margin-bottom:70px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #ffcd09;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_saveButton__2447U:hover {
	opacity:0.7;
}

.Style_sendButton__2J53d {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:black;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: -webkit-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(349.77deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(100.23deg, #FFE992 0%, #FFCD09 100%);
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_sendButton__2J53d:hover {
	opacity:0.7;
}

.Style_buttContainer__11Ukp {
	
}

.Style_smallLoader__1evPh {
	margin-top:10px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	border: 5px solid white;
	border-top: 5px solid #3498db;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
	width: 30px;
	height: 30px;
	-webkit-animation: Style_spin__Z_RlU 2s linear infinite;
	   -moz-animation: Style_spin__Z_RlU 2s linear infinite;
	     -o-animation: Style_spin__Z_RlU 2s linear infinite;
	        animation: Style_spin__Z_RlU 2s linear infinite;
}

.Style_reject__wjxL7 {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__2HMHF {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__2-OtM {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}

.Style_line__1at_m {
	display:block;
	width:100%;
	height:19px;
}

.Style_statusGreen__tQjD2 {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#006400;
}

.Style_hideButt__3Z4W_ {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #FF0000;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_statusRed__3EISq {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:20px;
	margin-top:25px;
	line-height:20px;
	text-align:center;
	color:#ff0000;
}

.Style_showButt__3yzEe {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ffffff;
	margin-top:10px;
	width:100%;
	height:40px;
	line-height:40px;
	text-align:center;
	background: #006400;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	        border-radius: 6px;
	cursor:pointer;
	-webkit-transition:opacity 0.3s;
	-o-transition:opacity 0.3s;
	-moz-transition:opacity 0.3s;
	transition:opacity 0.3s;
}

.Style_addFavoriteBox__1tLbg {
	border:1px solid black;
	z-index:100000;
	width:350px;
	padding:10px;
	position:fixed;
	top:50px;
	left:-webkit-calc(50vw - 175px);
	left:-moz-calc(50vw - 175px);
	left:calc(50vw - 175px);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(36.02%, #FDFDFD), to(#FFFBEE));
	background: -webkit-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -moz-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: -o-linear-gradient(top, #FDFDFD 36.02%, #FFFBEE 100%);
	background: linear-gradient(180deg, #FDFDFD 36.02%, #FFFBEE 100%);
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	padding:10px;
}

.Style_addFavText__25cu_ {
	width:100%;
	height:30px;
	border:1px solid #cccccc;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:10px;
	padding-right:10px;
}

.Style_fClose__2BUpb {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
	display:block;
	position:absolute;
	right:10px;
	top:10px;
	cursor:pointer;
}

.Style_fArea__3YLjb {
	height:125px;
	overflow-y:auto;
	overflow-x:hidden;
}

.Style_fHeader__27lzU {
	font-family: 'Mitr', sans-serif;
	font-weight:700;
	font-size:24px;
	text-align:center;
	color:#000000;
}

.Style_fLabel__2Kcoh {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fNothing__2MRfy {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#aaaaaa;
	text-align:center;
	margin-top:5px;
	margin-bottom:5px;
}

.Style_fAdd__3GDgx {
	background: -webkit-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -moz-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: -o-linear-gradient(358.14deg, #FFE992 0%, #FFCD09 100%);
	background: linear-gradient(91.86deg, #FFE992 0%, #FFCD09 100%);
	height:30px;
	width:100%;
	cursor:pointer;
	text-align:center;
	line-height:30px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}
.Style_fAdd__3GDgx.Style_inactive__3n030 {
	opacity:0.3;
	cursor:not-allowed;
}

.Style_fPlus__3mxO6 {
	background-color: #00aa00;
	border:1px solid black;
	height:23px;
	width:90px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:10px;
	display:block;
	cursor:pointer;
	text-align:center;
	line-height:23px;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
	color:#ffffff;
}

.Style_fLineCon__27Mfs {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-align-items: center;
	   -moz-box-align: center;
	        align-items: center;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	border-bottom:1px solid black;
	margin-bottom:5px;
}

.Style_fLineText__3oxys {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:40px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineLabel__3lG9w {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_fLineSelect__2gbR0 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	width:100px;
	height:30px;
	padding-left:5px;
	padding-right:5px;
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	outline:none;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	background: #FAFAFA;
	border: 1px solid #EAEAEA;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
}

.Style_fLineX__2ethc {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#ff0000;
	margin-left:10px;
	cursor:pointer;
}

.Style_linkLineCon__1ZSIq {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	margin-left:2px;
	margin-right:2px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display:block;
	text-align:center;
}


@-webkit-keyframes Style_spin__Z_RlU {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@-moz-keyframes Style_spin__Z_RlU {
  0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}


@-o-keyframes Style_spin__Z_RlU {
  0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes Style_spin__Z_RlU {
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@page {
  size: A4;
  margin: 0;
}
.Style_page__zfpRM {
	width:210mm;
	min-height:297mm;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-top:10mm;
	padding-bottom:10mm;
}
.Style_inner__1Y7i7 {
	width:190mm;
	min-height:277mm;
	display:block;
	margin-left:auto;
	margin-right:auto;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-left:10mm;
	padding-right:10mm;
}
.Style_header__g4Hx2 {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:24px;
}
.Style_header2__WOXVA {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:20px;
}
.Style_header3__2v70I {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	margin-bottom:10px;
}
.Style_local1__3Wk03 {
	text-align:left;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:14px;
}
.Style_table__3FGK6 {
	font-family: 'Sarabun', sans-serif;
	border:1px solid black;
	width:100%;
	margin-top:20px;
	text-align:center;
}
.Style_row__C5fJZ {
	border:1px solid black;
}

.Style_header4__22tdg {
	text-align:right;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:20px;
	margin-top:40px;
	margin-bottom:10px;
}

.Style_logo__xTmQ6 {
	margin-top:1px;
	width:100px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
  }
}

@page {
  size: A4;
  margin: 0;
}
.Style_page__1uEx4 {
	width:210mm;
	min-height:297mm;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-top:10mm;
	padding-bottom:10mm;
}
.Style_inner__3fsTc {
	width:190mm;
	min-height:277mm;
	display:block;
	margin-left:auto;
	margin-right:auto;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-left:10mm;
	padding-right:10mm;
}
.Style_header__1ND_s {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:24px;
	line-height:50px;
}
.Style_header2__2kwEr {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:20px;
}
.Style_header3__mff9K {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	margin-bottom:10px;
}
.Style_local1__CLp6Y {
	text-align:left;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:14px;
}
.Style_table__AhOxt {
	font-family: 'Sarabun', sans-serif;
	border:1px solid black;
	width:100%;
	margin-top:20px;
	text-align:center;
}
.Style_row__2sUhQ {
	border:1px solid black;
}

.Style_header4__HN3da {
	text-align:right;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:20px;
	margin-top:40px;
	margin-bottom:10px;
}

.Style_logo__2Ppka {
	margin-top:1px;
	width:100px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
  }
}

@page {
  size: A4;
  margin:0;
}

.Style_page__2Wyk1 {
	width:210mm;
	height:297mm;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding-top:20mm;
	padding-bottom:20mm;
	padding-left:16mm;
	padding-right:16mm;
}

.Style_boldIL__37qHo {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	display:inline;
}

.Style_normalIL__3Mxcd {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:11px;
	display:inline;
}

.Style_topBlank__2LrI1 {
	display:inline-block;
	width:30px;
}

.Style_second__16Ybh {
	margin-top:10px;
}

.Style_secondNum__2KLrC {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	display:inline;
	margin-left:100px;
}

.Style_secondDate__2TMuw {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	display:inline;
}

.Style_numValue__T0NIa {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	display:inline-block;
	width:70px;
	margin-left:15px;
}

.Style_checkList__1Vz6k {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:11px;
	display:inline-block;
	width:110px;
	margin-left:15px;
}

.Style_inner__3RHei {
	width:100%;
	border:1px solid black;
	height:240mm;
	margin-top:20px;
}

.Style_innerus__2gtGn {
	width:100%;
	border-bottom:1px solid black;
	height:35mm;
}
.Style_innerus3__1evb9 {
	width:100%;
	border-bottom:1px solid black;
	height:15mm;
}
.Style_innerus4__3Y3vc {
	width:100%;
	border-bottom:1px solid black;
	height:89mm;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
}
.Style_innerus5__8g65- {
	width:100%;
	border-bottom:1px solid black;
	height:8mm;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
}
.Style_innerus6__10bNN {
	width:100%;
	border-bottom:1px solid black;
	height:8mm;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:11px;
	line-height:8mm;
	text-indent:7px;
	background-color:#eaeaea
}

.Style_us__2gppt {
	margin-top:5px;
	padding-left:3px;
}
.Style_us2__21Fo7 {
	margin-top:10px;
	padding-left:3px;
}
.Style_us3__X62di {
	margin-top:5px;
	padding-left:3px;
}
.Style_us4__2UZfd {
	margin-top:5px;
	padding-left:158px;
}
.Style_boldILHead__1XMUM {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:14px;
	display:inline;
	text-decoration:underline;
}

.Style_normalLeft__3nX7S {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	display:inline;
}

.Style_normalUs__13Gs3 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	display:inline;
	margin-left:220px;
}
.Style_normalUs2__Z3fWH {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	display:inline;
	margin-left:245px;
}
.Style_normalUs3__99Z8z {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	display:inline;
	margin-left:56px;
}

.Style_normalSmall__2CHWX {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:11px;
	display:inline;
	margin-left:28px;
}

.Style_numValueUs__3Kodz {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	display:inline-block;
	margin-left:15px;
}

.Style_numValueUsAdd__4PPj- {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	display:inline-block;
	margin-left:7px;
}

.Style_column1__2QgKV {
	width:50%;
	height:100%;
	border-right:1px solid black;
}

.Style_column2__37Ce6 {
	width:16%;
	height:100%;
	border-right:1px solid black;
}
.Style_column3__3IDcG {
	width:16%;
	height:100%;
	border-right:1px solid black;
}
.Style_column4__3mnjn {
	width:18%;
	height:100%;
}
.Style_topCol__3wPxC {
	width:100%;
	height:40px;
	border-bottom:1px solid black;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
}
.Style_topText__3u-65 {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	text-align:center;
}

.Style_lineStart__zu4z7 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-left:3px;
	line-height:15px;
	margin-top:3px;
}
.Style_lineTab__26ygO {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-left:16px;
	line-height:15px;
	margin-top:3px;
}
.Style_lineTabTab__3ZPrt {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-left:29px;
	line-height:15px;
	margin-top:3px;
}
.Style_lineTabTabTab__2379K {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-left:42px;
	line-height:15px;
	margin-top:3px;
}
.Style_lineValue__3bEp9 {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
}
.Style_valueCol__2Th_W {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	text-align:center;
	margin-top:253px;
}

.Style_column1x__1u193 {
	width:-webkit-calc(66% + 1px);
	width:-moz-calc(66% + 1px);
	width:calc(66% + 1px);
	height:100%;
	border-right:1px solid black;
	line-height:8mm;
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
}

.Style_column2x__2eGfr {
	width:16%;
	height:100%;
	border-right:1px solid black;
	line-height:8mm;
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
}
.Style_column3x__2x20U {
	width:18%;
	height:100%;
	line-height:8mm;
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
}

.Style_column1xx__2WOvh {
	width:30%;
	height:100%;
	border-right:1px solid black;
	line-height:8mm;
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
}

.Style_column2xx__2PS9Q {
	width:70%;
	height:100%;
	line-height:8mm;
	padding-left:12px;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:12px;
	background-color:#F3F3F3;
}

.Style_column1xxx__1gjXv {
	width:12%;
	height:100%;
	line-height:8mm;
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
}

.Style_column2xxx__3Dox1 {
	width:22%;
	height:100%;
	line-height:8mm;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	border-left:1px solid black;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-align-items:center;
	   -moz-box-align:center;
	        align-items:center;
}

.Style_boxn__2bNkT {
	width:3mm;
	height:3mm;
	border:1px solid black;
	display:inline-block;
	margin-left:4px;
	margin-right:4px;
	font-size:10px;
}
.Style_boxf__fuGEe {
	width:3mm;
	height:3mm;
	border:1px solid black;
	display:inline-block;
	margin-left:4px;
	margin-right:4px;
	background-color:#000000;
}

.Style_botText__1FKoD {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-top:5px;
	margin-left:50px;
}
.Style_botText2__6d0Tc {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-top:12px;
}
.Style_botText3__3opX7 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-top:3px;
	margin-left:120px;
}
.Style_botText4__1LUe3 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:12px;
	margin-top:8px;
	margin-left:140px;
}
.Style_botText5__1X0wj {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:8px;
	margin-top:8px;
	margin-left:0px;
}
.Style_botTextLeftLeft__1B93x {
	margin-left:50px;
	display:inline;
}
.Style_botTextLeft__3cCaH {
	margin-left:20px;
	display:inline;
	position:relative;
}
.Style_botTextLeftPic__1QvQ7 {
	position:absolute;
	width:140px;
	top:-40px;
}
.Style_botTextRight__2n3zC {
	display:inline;
	margin-left:150px;
}

@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
  }
}

@page {
  size: A4;
  margin:0;
}

.Style_page__Y5SJN {
	width:210mm;
	height:297mm;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	padding-top:20mm;
	padding-bottom:20mm;
	padding-left:16mm;
	padding-right:16mm;
}

.Style_header__7hInA {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:25px;
	text-align:center;
}

.Style_rightText__1S_XT {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:16px;
	text-align:right;
	margin-top:15px;
}

.Style_topRow__YWm9o {
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:16px;
	line-height:25px;
	margin-top:20px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	border:1px solid black;
	height:25px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	text-align:center;
}

.Style_tr1__3jAtK {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:20%;
}
.Style_tr2__4348S {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:40%;
}
.Style_tr3__32vUe {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:20%;
}
.Style_tr4__28zeA {
	width:20%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_dataRow__RhlRI {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	border:1px solid black;
	border-top:none;
	height:40px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	text-align:center;
}

.Style_td1__5jCCY {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:20%;
}
.Style_td2__1RhXz {
	padding-top:5px;
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:40%;
	font-size:12px;
	line-height:15px;
}
.Style_td3__29i1X {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:13%;
}
.Style_td4__2UUqY {
	border-right:1px solid black;
	width:7%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}
.Style_td5__3lEvW {
	width:20%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.Style_preRow__2J-U1 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	border:1px solid black;
	border-top:none;
	height:40px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	text-align:center;
	margin-left:20%;
	margin-right:20%;
}

.Style_tp1__2FCgh {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:66.7%;
}
.Style_tp2__7JDFq {
	border-right:1px solid black;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:21.7%;
}
.Style_tp3__1oqTz {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	width:11.6%;
}

.Style_lastRow__xYwIV {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:50px;
	border:1px solid black;
	border-top:none;
	height:50px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	text-align:left;
	padding-left: 5px;
	margin-left:20%;
	margin-right:20%;
}

.Style_downtext1__3O0F1 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:60px;
}

.Style_righttextname3__193oY {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:60px;
	text-align: right;
}

.Style_righttextname4__lc7w1 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	text-align: right;
	padding-right:40px;
}

.Style_righttextname__1NxIV {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	text-align: right;
}

.Style_righttextname2__3ou57 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	text-align: right;
	padding-right:35px;
}

.Style_downtext__2bvgs {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:5px;
}

.Style_downtextlast__kzvl9 {
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:18px;
	margin-top:80px;
}

@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
  }
}

@page {
  size: A4;
  margin: 0;
}
.Style_page__27xVj {
	width:210mm;
	min-height:297mm;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-top:10mm;
	padding-bottom:10mm;
}
.Style_inner__2__R0 {
	width:190mm;
	min-height:277mm;
	display:block;
	margin-left:auto;
	margin-right:auto;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-left:10mm;
	padding-right:10mm;
}
.Style_header__2tsnN {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:24px;
}
.Style_header2__21nTa {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:20px;
}
.Style_header3__2_var {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	margin-bottom:10px;
}
.Style_local1__hMpEe {
	text-align:left;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:14px;
}
.Style_table__2V8ah {
	font-family: 'Sarabun', sans-serif;
	border:1px solid black;
	width:100%;
	margin-top:20px;
	text-align:center;
}
.Style_row__3ZXp6 {
	border:1px solid black;
}

.Style_header4__2VgA5 {
	text-align:right;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:20px;
	margin-top:40px;
	margin-bottom:10px;
}

.Style_logo__rNSP- {
	margin-top:1px;
	width:100px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
  }
}

@page {
  size: A4;
  margin: 0;
}
.Style_page__1fFNi {
	width:210mm;
	min-height:297mm;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-top:10mm;
	padding-bottom:10mm;
}
.Style_inner__2a4aJ {
	width:190mm;
	min-height:277mm;
	display:block;
	margin-left:auto;
	margin-right:auto;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
	border:1px solid black;
	padding-left:10mm;
	padding-right:10mm;
}
.Style_header__2wBnR {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:24px;
}
.Style_header2__18YYO {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:20px;
}
.Style_header3__39Zfn {
	text-align:center;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:16px;
	margin-top:10px;
	margin-bottom:10px;
}
.Style_local1__FjrpB {
	text-align:left;
	font-family: 'Sarabun', sans-serif;
	font-weight:400;
	font-size:14px;
}
.Style_table__2_jZw {
	font-family: 'Sarabun', sans-serif;
	border:1px solid black;
	width:100%;
	margin-top:20px;
	text-align:center;
}
.Style_row__7JiNA {
	border:1px solid black;
}

.Style_header4__2-xtB {
	text-align:right;
	font-family: 'Sarabun', sans-serif;
	font-weight:700;
	font-size:20px;
	margin-top:40px;
	margin-bottom:10px;
}

.Style_logo__MBZWJ {
	margin-top:1px;
	width:100px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
  }
}

.Style_shopPage__2AgYQ {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	position:relative;
}

.Style_blanker__1c1W- {
	height:80px;
}

.Style_bigHeader__2PuDy {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	text-align:center;
}

.Style_nothing__1Yg6X {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.Style_messageBox__2eX3n {
	display:block;
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	width:100%;
	max-width:500px;
	border:1px solid black;
	padding:10px 10px 10px 10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_content__Q8VxL {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height:18px;
}

.Style_timein__2mY1R {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	margin-top:20px;
}

.Style_box0__1KXqt {
	background-color:#90ee90;
	border:1px solid #013220;
}

.Style_box1__kvQjC {
	background-color:#7fffd4;
	border:1px solid #3399ff;
}

.Style_box2__UUHRh {
	background-color:#ffa500;
	border:1px solid #ff4500;
}

.Style_box3__3CVGF {
	background-color:#ffc0cb;
	border:1px solid #ff0000;
}

.Style_sheetWrapper__3RW1e {
	margin-top:35px;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_semSelector__1IvdL {
	-webkit-appearance:none;
	   -moz-appearance:none;
	        appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #EAEAEA;
	height:34px;
	color:#666666;
	cursor:pointer;
}

.Style_semSelector__1IvdL:hover {
	background-color:#F5F5F5;
}

.Style_semSelector__1IvdL.Style_mobile__2afnw {
	min-width:142px;
	border:none;
}

.Style_semSelector__1IvdL.Style_mobile__2afnw.Style_last__1Lxy1 {
	min-width:142px;
	border-left:1px solid #EAEAEA;
}

.Style_semSelector__1IvdL.Style_mobile__2afnw.Style_sort__1h0kv {
	width:100%;
}

.Style_search__1WFXX {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border:none;
	height:34px;
	width:280px;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	color:#666666;
}

.Style_search__1WFXX.Style_mobile__2afnw {
	border-right:none;
}

.Style_rowWrapper__2PtiC {
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	-webkit-flex-direction: row;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	        flex-direction: row;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.Style_barMobile__2b4ZV {
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.Style_barMobile__2b4ZV.Style_searcher__9CdOT {
	width:290px;
	margin-top:40px;
}

.Style_barMobile__2b4ZV.Style_filter__b49o3 {
	width:290px;
	margin-top:20px;
}

.Style_filterBar__3wVUe {
	margin-top:40px;
	display:-webkit-flex;
	display:-moz-box;
	display:flex;
	-webkit-flex-direction:row;
	   -moz-box-orient:horizontal;
	   -moz-box-direction:normal;
	        flex-direction:row;
	-webkit-justify-content:center;
	   -moz-box-pack:center;
	        justify-content:center;
	-webkit-align-items:flex-start;
	   -moz-box-align:start;
	        align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	   -moz-box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	        box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	        border-radius: 5px;
	width:420px;
	margin-left:auto;
	margin-right:auto;
}

.Style_reject__3YZDn {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

.Style_rejectHeader__1DJir {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.Style_rejectReason__CewZ5 {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}
.MoreSheetPage {
	position: absolute;
	left: 0px;
	top: 0px;
	max-width:100%;
}

.MoreSheetPage-enter {
	opacity: 0;
}

.MoreSheetPage-enter-active {
	opacity: 1;
	-webkit-transition: opacity 300ms;
	-o-transition: opacity 300ms;
	-moz-transition: opacity 300ms;
	transition: opacity 300ms;
}

.MoreSheetPage-exit {
	opacity: 1;
}

.MoreSheetPage-exit-active {
	opacity: 0;
	-webkit-transition: opacity 300ms;
	-o-transition: opacity 300ms;
	-moz-transition: opacity 300ms;
	transition: opacity 300ms;
}
