.MoreSheetPage {
	position: absolute;
	left: 0px;
	top: 0px;
	max-width:100%;
}

.MoreSheetPage-enter {
	opacity: 0;
}

.MoreSheetPage-enter-active {
	opacity: 1;
	transition: opacity 300ms;
}

.MoreSheetPage-exit {
	opacity: 1;
}

.MoreSheetPage-exit-active {
	opacity: 0;
	transition: opacity 300ms;
}