.shopPage {
	width:100vw;
	max-width:100%;
	min-height:100vh;
	background-color:#FFFFFF;
	box-sizing: border-box;
	position:relative;
}

.blanker {
	height:80px;
}

.bigHeader {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	text-align:center;
}

.nothing {
	margin-top:20px;
	color:#BDBDBD;
	text-align:center;
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:14px;
}

.messageBox {
	display:block;
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	width:100%;
	max-width:500px;
	border:1px solid black;
	padding:10px 10px 10px 10px;
	box-sizing:border-box;
}

.content {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height:18px;
}

.timein {
	font-family: 'Mitr', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	margin-top:20px;
}

.box0 {
	background-color:#90ee90;
	border:1px solid #013220;
}

.box1 {
	background-color:#7fffd4;
	border:1px solid #3399ff;
}

.box2 {
	background-color:#ffa500;
	border:1px solid #ff4500;
}

.box3 {
	background-color:#ffc0cb;
	border:1px solid #ff0000;
}

.sheetWrapper {
	margin-top:35px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.semSelector {
	appearance:none;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border-top:none;
	border-bottom:none;
	border-right:none;
	border-left:1px solid #EAEAEA;
	height:34px;
	color:#666666;
	cursor:pointer;
}

.semSelector:hover {
	background-color:#F5F5F5;
}

.semSelector.mobile {
	min-width:142px;
	border:none;
}

.semSelector.mobile.last {
	min-width:142px;
	border-left:1px solid #EAEAEA;
}

.semSelector.mobile.sort {
	width:100%;
}

.search {
	display:block;
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:16px;
	line-height:34px;
	outline:none;
	padding-left:10px;
	padding-right:10px;
	padding-top:0;
	padding-bottom:0;
	border:none;
	height:34px;
	width:280px;
	box-sizing: border-box;
	color:#666666;
}

.search.mobile {
	border-right:none;
}

.rowWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.barMobile {
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	box-sizing: border-box;
	box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	border-radius: 5px;
	margin-left:auto;
	margin-right:auto;
}

.barMobile.searcher {
	width:290px;
	margin-top:40px;
}

.barMobile.filter {
	width:290px;
	margin-top:20px;
}

.filterBar {
	margin-top:40px;
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:flex-start;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	box-sizing: border-box;
	box-shadow: 0px 10px 20px rgba(211, 211, 211, 0.2), 0px 2px 4px rgba(211, 211, 211, 0.05);
	border-radius: 5px;
	width:420px;
	margin-left:auto;
	margin-right:auto;
}

.reject {
	width:100%;
	background-color:#ffe6e6;
	border:1px solid #ff0000;
	padding-left:10px;
	padding-right:10px;
	padding-top:10px;
	padding-bottom:10px;
	box-sizing:border-box;
}

.rejectHeader {
	font-family: 'Mitr', sans-serif;
	font-weight:500;
	font-size:16px;
	color:#ff0000;
}

.rejectReason {
	font-family: 'Mitr', sans-serif;
	font-weight:400;
	font-size:14px;
	color:#000000;
	padding-left:20px;
}